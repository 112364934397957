import arrowNitcomaxFooter from "assets/icons/iconsNitcomax/arrowNitcomaxFooter.svg";
import footerImg from "assets/images/imagesNitcomax/nitcomaxFooterImg.webp";
import img from "assets/images/imagesNitcomax/nitcomaxImg.webp";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { settingsStore } from "store/settingStore";
import About from "./components/About";
import FAQ from "./components/FAQ";
import Hero from "./components/Hero";
import { Link } from "react-scroll";
const Nitcomax = () => {
  const { t } = useTranslation("index");
  const direction = settingsStore((state) => state.direction);
  return (
    <div>
      <Helmet>
        <title>Nitcomax</title>
        <meta property="og:site_name" content="Nitcomax" />
        <meta
          property="og:url"
          content="https://business-hub.r-link.io/nitcomax"
        />
        <meta
          name="description"
          content="Fuel your digital success with Nitcomax—a dynamic hub of marketing, design, and development solutions. Transform your online presence and thrive in the digital landscape."
        />
        <meta property="og:title" content="Nitcomax" />
        <meta
          property="og:type"
          content="Web & Mobile Development , Digital Marketing ,Branding & Strategy and Interaction Design"
        />
        <meta name="twitter:site" content="@Nitcomax" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nitcomax" />
        <meta
          name="twitter:description"
          content="Fuel your digital success with Nitcomax—a dynamic hub of marketing, design, and development solutions. Transform your online presence and thrive in the digital landscape."
        />
      </Helmet>
      <div className="h-full">
        <Hero />
      </div>
      <div id="about">
        <About />
      </div>
      <div className="mt-10 lg:p-20 ">
        <img
          loading="lazy"
          data-aos="flip-right"
          data-aos-duration="1000"
          src={img}
          alt=""
        />
      </div>
      <div className="flex flex-col p-6 md:p-20 font-poppins">
        <div className="flex-flex-col">
          <h1 className="text-primary-alhussni-color text-xlarge font-[600]">
            {t("The right media")}
          </h1>
          <div className="text-meduim font-[600] mt-12">
            {t("nitcomax right media")}
          </div>
        </div>
        <div className="flex justify-between max-sm:flex-col items-center my-12 md:my-10">
          <div className="flex flex-col max-sm:mb-8 w-full sm:w-2/5 lg:w-1/3">
            <div className="text-[12] text-[#888888] mb-1">
              {t("PORTFOLIO")}
            </div>
            <div className="text-meduim font-[600]">
              {t("We create places that connect, sustain & inspire")}
            </div>
          </div>
          <div className="flex flex-col sm:w-2/5 lg:w-1/3">
            <div className="text-main font-jakarta">
              {t("nitcomax With the support of MHM")}
            </div>
            <a
              href="/"
              className={`relative flex w-[200px]  before:transition-all before:duration-1000 font-bold mt-4  before:absolute ${
                direction === "ltr"
                  ? "before:left-[-15px]"
                  : "before:right-[-15px]"
              }  before:z-[-1] before:top-[-50%] before:w-[50px] before:h-[50px] before:bg-primary-alhussni-color before:rounded-full`}
            >
              {t("VIEW ALL PORTFOLIO")}
            </a>
          </div>
        </div>
      </div>
      <div>
        <FAQ />
      </div>
      <div className="bg-black p-4 md:px-20 lg:px-28 pt-20 font-poppins">
        <div className="flex justify-end w-full">
          <img
            className="w-[90%] lg:w-[80%]"
            loading="lazy"
            src={footerImg}
            alt=""
          />
        </div>
        <div className="relative translate-y-[-50%] flex items-center text-large font-[600] p-8 xl:p-20 bg-[#222222] justify-between w-[90%] lg:w-[80%]">
          <div className="sm:w-2/3 text-white">
            {t("Let’s Make Something Great")}
            <span className="text-primary-alhussni-color ">
              {" "}
              {t("Together")}!
            </span>
          </div>
          <Link
            to="contact"
            className={`flex justify-center max-sm:absolute max-sm:z-10 ${
              direction === "ltr" ? "max-sm:right-0" : "max-sm:left-0"
            }  max-sm:translate-x-[20%] max-sm:translate-y-[60%]`}
          >
            <div className="w-[100px] h-[100px] md:w-[130px] md:h-[130px] rounded-full bg-[#E3B27D] flex flex-col justify-center items-center text-white p-1">
              <div className="text-[12px] text-center md:mb-1">
                {t("CONTACT WITH US")}
              </div>
              <div>
                <img
                  className="w-2 md:w-3 md:mt-1"
                  loading="lazy"
                  src={arrowNitcomaxFooter}
                  alt=""
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Nitcomax;
