import { useTranslation } from 'react-i18next';
import { useNaiaLogic } from '../hooks/useNaiaLogic';

const HowNaiaWorks = (props) => {
    const { HowWorks } = useNaiaLogic()
    const { t } = useTranslation('index')
    return (
        <>
            {/* i am taking data here from useNaiaLogic by name HowWorks*/}
            {HowWorks?.map((item, index) => (
                <div key={index} className='h-full flex flex-col justify-start items-center'>
                    <div className='flex justify-center items-center w-[200px] h-auto md:h-[300px]'>
                        <img className='' loading='lazy' src={item.src} alt="" />
                    </div>
                    <div className='text-large max-sm:text-xlarge font-[500] font-cocon text-center max-h-1/5 mt-0'>
                        {t(item.title)}
                    </div>
                </div>
            ))}
        </>
    )
}

export default HowNaiaWorks
