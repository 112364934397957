import alhussni from "assets/icons/alhussniName.svg";
import businessHupLogo from "assets/images/imagesHome/hup.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SocialMediaIcons } from "static";
import naia from "../../assets/icons/naiaLogo.svg";
import nitcomax from "../../assets/icons/nitcomaxLogoLight.svg";
import qatar from "../../assets/icons/qatarLogo.svg";
import axios from "axios";
import { useRef } from "react";
const Footer = () => {
  const { t } = useTranslation("index");
  const location = useLocation();
  const imagePathMap = {
    alhussni: alhussni,
    naia: naia,
    furniture: qatar,
    nitcomax: nitcomax,
  };
  const DataBussnisHup = {
    location: t("loc"),
    phone: "+974 66688 584",
    email: "info@businesshub.com.qa",
  };
  const imagePath = location.pathname.substring(1);
  const selectedImage = imagePathMap[imagePath];
  const message = useRef(null);
  // const handelchangemessage =()=>{
  // }
  const BussinesHubisntagramLink =
    "https://www.instagram.com/businesshubqa?igsh=MXhoZ3JnMXA3YzVjeg%3D%3D&utm_source=qr";
  const NaiaisntagramLink =
    "https://www.instagram.com/naiawater.qatar?igsh=MTB6Z2Ryem4xMjdkbw==";
  const FurnitureisntagramLink =
    "https://www.instagram.com/qataronlinefurniture?igsh=MTV5d2doYWNlYm9iZg==";
  const NitcomaxisntagramLink =
    "https://www.instagram.com/nitcomaxqatar?igsh=bmhranVhdXV6eXBu&utm_source=qr";
  const AlhusniisntagramLink =
    "https://www.instagram.com/alhussnigroup?igsh=aWdrdjM1enY4OWUw";

  const sendMessage = () => {
    // console.log(message)
    const msg = message.current.value;

    console.log(msg);

    axios
      .post("https://formsubmit.co/ajax/info@businesshub.com.qa", msg)
      .then((response) => console.log(response));
    alert("thanks");
    message.current.value = "";
  };
  return (
    <div id="contact">
      <div
        className={`${
          location.pathname === "/"
            ? "grid md:grid-cols-3 gap-8"
            : "flex justify-between max-sm:flex-col py-8"
        } ${location.pathname === "/furniture" ? "bg-[#FFF3E9]" : ""} ${
          location.pathname === "/alhussni" || location.pathname === "/nitcomax"
            ? "bg-black text-white"
            : ""
        }   px-4 md:px-20 pt-4 md:pt-4 font-inter`}
      >
        {location.pathname === "/" ? (
          <div className="flex flex-col justify-between max-md:hidden">
            <div className="font-bold text-[30px] mb-2">{t("Pages")}</div>
            <a href="/naia">{t("Naia Water")}</a>
            <a href="/alhussni">{t("AlHussni Perfume")}</a>
            <a href="/furniture">{t("Qatar Online Furniture")}</a>
            <a href="/nitcomax">{t("Nitcomax")}</a>
          </div>
        ) : (
          <div className="sm:w-1/2 md:w-1/3 w-1/1 flex flex-col justify-center max-md:m-6 lg:px-8">
            <div className="max-sm:w-full">
              <img
                className={`${
                  location.pathname === "/alhussni" ? "" : "mx-auto"
                } mb-2  w-1/1 md:w-1/2`}
                src={selectedImage}
                alt=""
              />
            </div>
            {location.pathname === "/alhussni" ? (
              <div className="my-0">
                <div className="font-[700] text-main flex">
                  {t("Company")}:
                  <span className="font-normal ms-2">{t("loc")}</span>
                </div>
                <div className="font-[700] text-main my-2">
                  {t("P.O.Box")} :{" "}
                  <span className="font-normal">3565 - CR.: 145803</span>
                </div>
                <div className="font-[700] text-main">
                  {t("Email")} :{" "}
                  <a
                    href={`mailto:${DataBussnisHup.email}`}
                    className="font-normal text-white"
                  >
                    {DataBussnisHup.email}
                  </a>
                </div>
              </div>
            ) : null}
            <div
              className={`${
                location.pathname === "/alhussni" ? "justify-start" : "mx-auto"
              } flex mt-4 items-center `}
            >
              {SocialMediaIcons({
                color: `${
                  location.pathname === "/alhussni"
                    ? "black"
                    : location.pathname === "/nitcomax"
                    ? "black"
                    : location.pathname === "/furniture"
                    ? "#FFF3E9"
                    : "white"
                }`,
                fill: `${
                  location.pathname === "/naia"
                    ? "#61c5d8"
                    : location.pathname === "/alhussni"
                    ? "white"
                    : location.pathname === "/furniture"
                    ? "black"
                    : "#B2A265"
                }`,
              }).map((item, index) => (
                <a
                  className="w-6 sm:w-10 my-1 "
                  key={index}
                  // target="_blank"
                  href={
                    // index === 0
                    //   ? location.pathname === "/naia"
                    //     ? NaiaisntagramLink
                    //     : location.pathname === "/alhussni"
                    //     ? AlhusniisntagramLink
                    //     : location.pathname === "/nitcomax"
                    //     ? NitcomaxisntagramLink
                    //     : location.pathname === "/furniture"
                    //     ? FurnitureisntagramLink
                    //     : BussinesHubisntagramLink:
                    index === 0
                      ? location.pathname === "/naia"
                        ? NaiaisntagramLink
                        : location.pathname === "/alhussni"
                        ? AlhusniisntagramLink
                        : location.pathname === "/nitcomax"
                        ? NitcomaxisntagramLink
                        : location.pathname === "/furniture"
                        ? FurnitureisntagramLink
                        : BussinesHubisntagramLink
                      : "/home"
                    // location.pathname === "/naia"
                    //   ? NaiaisntagramLink
                    //   : location.pathname === "/alhussni"
                    //   ? AlhusniisntagramLink
                    //   : location.pathname === "/nitcomax"
                    //   ? NitcomaxisntagramLink
                    //   : location.pathname === "/furniture"
                    //   ? FurnitureisntagramLink
                    //   : BussinesHubisntagramLink
                  }
                >
                  {item.src}
                </a>
              ))}
              <h3
                className={`font-bold me-2 text-lg text-${
                  location.pathname === "/naia"
                      ? 'black'
                      : location.pathname === "/alhussni"
                      ? 'white'
                      : location.pathname === "/nitcomax"
                      ? 'white'
                      : location.pathname === "/furniture"
                      ? 'black'
                      : 'black'
                }`}
              >
                
                {t("Instagram")}
              </h3>
            </div>
          </div>
        )}
        {location.pathname === "/" ? (
          <div>
            <div className="flex flex-col justify-between max-md:hidden">
              <div className="font-bold text-[30px] mb-2">{t("Contact")}</div>
              <div className="my-2 flex">
                {t("Email")}:{" "}
                <a className="text-black ms-2" href="mailto:Sales@rbhomes.ae">
                  {DataBussnisHup.email}
                </a>
              </div>
              <div className="flex">
                {t("Location")}:{" "}
                <span className="ms-2">{DataBussnisHup.location} </span>
              </div>
            </div>
            <div className="max-md:mt-[150px] max-sm:mt-[100px]">
              <div className="font-bold text-main my-2">
                {t("Contact us for any further information")}
              </div>
              <div className="flex max-md:flex-col flex-wrap">
                <input
                  type="text"
                  placeholder={t("Message")}
                  // value={message}
                  ref={message}
                  // onChange={handelchangemessage()}
                  className="border border-primary-color rounded-lg py-4 px-2 me-4 mt-4"
                />
                <button className="flex mt-4" onClick={sendMessage}>
                  <div className="border border-primary-color rounded-lg py-4 px-8 h-full flex justify-center items-center max-md:px-12 font-bold">
                    {t("Send")}
                  </div>
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`flex flex-col ${
            location.pathname === "/"
              ? "sm:w-1/1"
              : "sm:w-1/2 md:w-1/3 max-md:m-6"
          }  justify-between`}
        >
          {location.pathname === "/" ? (
            <div>
              <img
                className="w-[90%] mb-4  mx-auto max-md:hidden"
                src={businessHupLogo}
                alt=""
              />
            </div>
          ) : (
            <>
              <div>
                <img
                  className="w-[100%] md:w-2/3 mb-1 mx-auto"
                  src={businessHupLogo}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-between max-md:mt-6">
                <div className="font-[700] text-main flex ">
                  {t("Company")}:{" "}
                  <span className="font-normal ms-2">
                    {DataBussnisHup.location}
                  </span>
                </div>
                <div className="font-[700] text-main my-2">
                  {t("Phone")} :{" "}
                  <a
                    href="tel:+974 66688 584"
                    className={`font-normal ms-2 ${
                      location.pathname === "/nitcomax" ||
                      location.pathname === "/alhussni"
                        ? "text-white"
                        : "text-dark"
                    }`}
                  >
                    {DataBussnisHup.phone}
                  </a>
                </div>
                <div className="font-[700] text-main">
                  {t("Email")} :{" "}
                  <a
                    href="mailto:info@businesshub.com.qa"
                    className={`font-normal ms-2 ${
                      location.pathname === "/nitcomax" ||
                      location.pathname === "/alhussni"
                        ? "text-white"
                        : "text-dark"
                    }`}
                  >
                    {DataBussnisHup.email}
                  </a>
                </div>
                <div className="flex mt-5 ">
                  <h3 className="font-bold me-2 text-lg">{t("Social Media:")}</h3>

                  {SocialMediaIcons({
                    color: `${
                      location.pathname === "/alhussni"
                        ? "black"
                        : location.pathname === "/nitcomax"
                        ? "black"
                        : location.pathname === "/furniture"
                        ? "#FFF3E9"
                        : "white"
                    }`,
                    fill: `${
                      location.pathname === "/naia"
                        ? "black"
                        : location.pathname === "/alhussni"
                        ? "white"
                        : location.pathname === "/furniture"
                        ? "black"
                        : "white"
                    }`,
                  }).map((item, index) => (
                    <a
                      className="w-6 sm:w-10  "
                      key={index}
                      href={
                        // index === 0
                        // ? "/"
                        index === 0
                          ? location.pathname === "/naia"
                            ? NaiaisntagramLink
                            : location.pathname === "/alhussni"
                            ? AlhusniisntagramLink
                            : location.pathname === "/nitcomax"
                            ? NitcomaxisntagramLink
                            : location.pathname === "/furniture"
                            ? FurnitureisntagramLink
                            : BussinesHubisntagramLink
                          : "/home"
                      }
                    >
                      {item.src}
                    </a>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {location.pathname === "/" ? (
        <div className="grid md:grid-cols-3 max-md:grid-cols-1 max-md:grid-rows-2 gap-8 py-6 px-4 md:px-20 font-inter items-center">
          <div className="flex md:col-start-2 items-center">
                
            <h3 className="font-bold me-1 text-lg"> {t("Social Media:")}</h3>
            {SocialMediaIcons({ fill: "black", color: "white" }).map(
              (item, index) => (
                <a
                  className="w-6 sm:w-10 my-1 mx-2"
                  key={index}
                  href={index === 0 ? BussinesHubisntagramLink : "/"}
                >
                  {item.src}
                </a>
              )
            )}
          </div>

          <div className="text-center md:col-start-3">
            © {t("Businesshub, all rights reserved")}.
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Footer;
