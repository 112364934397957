import React from 'react'
import { useTranslation } from 'react-i18next';
import { useHomeLogic } from '../hooks/useHomeLogic'
import { settingsStore } from 'store/settingStore';

const Advantages = () => {
    const { advantagesBusinessHup } = useHomeLogic()
    const Imgs = advantagesBusinessHup.imgs;
    const content = advantagesBusinessHup.content;
    const { t } = useTranslation('index')
    const direction = settingsStore((state) => state.direction);
    return (
        <div className='w-full h-full flex max-lg:flex-col gap-8 lg:gap-10 max-md:mt-8'>
            <div className={`lg:w-2/5 grid grid-cols-2 grid-rows-2 max-md:grid-cols-2 max-md:grid-rows-2 max-lg:grid-cols-4 max-lg:grid-rows-1 max-lg:gap-4 max-lg:mx-auto lg:p-8 max-sm:px-10`}>
                {/* i am taking data here from useHomeLogic by name advantagesBusinessHup */}
                {Imgs.map((item, index) => (
                    <div className='lg:p-4' key={index}>
                        <img loading='lazy'  className={`${index % 2 === 0 ? 'max-md:rounded-tl-full max-md:rounded-bl-full' : 'max-md:rounded-tr-full max-md:rounded-br-full '} ${direction === 'ltr' ? '' : 'rotate'} object-contain `} src={item.src} alt="" />
                    </div>
                ))}
            </div>
            <div className="lg:w-3/5 justify-center flex flex-col">
                <div className="text-large font-bold font-cocon" data-aos="fade-down" data-aos-duration="500">{t("Advantages & benefits of dealing with Business Hub:")}</div>
                <div className='flex flex-col'>
                    {/* i am taking data here from useHomeLogic by name advantagesBusinessHup */}
                    {content.map((item, index) => (
                        <div className="mt-8 flex items-center" data-aos="fade-up" data-aos-duration="500" data-aos-delay={200 * (index + 1)} key={index}>
                            <div className="relative text-meduim font-bold w-12 h-12 rounded-full flex justify-end items-center font-bebas bg-black me-4"> <div className='absolute w-7 h-10 bg-white flex justify-center items-center rounded-[50%] right-1'>{t(item.num)}</div></div>
                            <div className="xl:text-[20px] text-main flex-1 font-inter">{t(item.text)}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Advantages
