import React from 'react'
import { uesAlhussniLogic } from '../hooks/useAlhossniLogic';
import DistributionPlaces from 'pages/naia/components/DistributionPlaces';
import 'style/input.css';
import PerfumeBox from './PerfumeBox';
import bgService3 from 'assets/images/imagesAlhussni/alhussniBgImg1.webp';
import alhussniName from 'assets/icons/alhussniName.svg';
import bgService4 from 'assets/images/imagesAlhussni/alhussniBgImg2.webp';
import { useTranslation } from 'react-i18next';
import { settingsStore } from 'store/settingStore';
const ServicesAlhussni = () => {
    const direction = settingsStore((state) => state.direction);
    const { t } = useTranslation('index')
    const { list } = uesAlhussniLogic()
    return (
        <div className='font-nunito'>
            <div className=' bg-black text-white p-8 md:p-28'>
                <div className='text-xlarge font-[400] font-Cinzel'>{t('Services')}</div>
                <div className='px-0 xl:px-20 py-4'>
                    <div className='text-meduim font-[700]'><span className='text-xlarge me-2 font-Cinzel'>1</span> {t('IMPORT OF FRAGRANCES')}</div>
                    <div className='flex flex-col' >
                        <DistributionPlaces values={list} />
                        <div className='text-center w-full font-bold text-xlarge text-primary-alhussni-color font-Cinzel p-16'>{t('AND MANY MORE...')}</div>
                    </div>
                </div>
                <div className='px-0 xl:px-20 py-10'>
                    <div className='text-meduim font-[700]'> <span className='text-xlarge me-2 font-Cinzel'>2</span> {t('CUSTOMIZING & PRODUCING PERFUMES')}</div>
                    <div>
                        <PerfumeBox />
                    </div>
                </div>
            </div>
            <div className='mt-20 bg-white'>
                <div className={`h-full w-full md:text-white relative`}>
                    <div className={`${direction === 'ltr' ? '' : 'rotate'}`}><img loading='lazy' src={bgService3} alt="" /></div>
                    <div className={`${direction === 'ltr' ? 'left-0' : 'right-0'} max-md:p-8 md:absolute w-full md:w-1/2 lg:w-2/5 h-full top-0  z-10 flex flex-col p-4 md:p-10 justify-center items-start md:items-center max-sm:before:hidden before:absolute before:w-full before:h-full md:before:bg-black before:opacity-60 before:z-[-1]`}>
                        <div className='text-meduim text-center font-[700]'>
                            <span className='text-xlarge me-2 font-Cinzel'>3</span> {t("DIFFUSERS OIL'S TRADING")}
                        </div>
                        <p className='max-md:leading-[27px] text-[14px] sm:text-main md:text-center my-8 md:my-4 lg:my-8 '>{t('services 3 text')}</p>
                        <div className='max-md:hidden'>
                            <img loading='lazy' src={alhussniName} alt="" />
                        </div>
                    </div>
                </div>
                <p className=' max-sm:p-8 p-20 text-[14px] sm:text-meduim font-[500] text-center max-md:text-white max-md:bg-[#00000099] '>{t('text after services 3')}</p>
            </div>
            <div className='mt-20'>
                <div className={`h-full w-full text-white relative`}>
                    <div><img className='lg:h-[90vh]' src={bgService4} alt="" /></div>
                    <div className='absolute inset-0 flex items-center justify-center'>
                        <div className='sm:w-1/2 sm:h-1/2 max-md:p-4 absolute z-10 flex flex-col p-4 md:p-10 justify-center items-center before:absolute before:w-full before:h-full before:bg-black before:opacity-75 before:z-[-1]' data-aos='zoom-in' data-aos-duration='1000'>
                            <div className='text-meduim text-center font-[700]'>
                                <span className='text-xlarge me-2 font-Cinzel'> 4</span> {t('NATURAL MOISTURISER')}
                            </div>
                            <div className='text-large md:text-center mt-4 lg:my-8'>{t('FACE & BODY')}</div>
                        </div>
                        <div className='absolute max-sm:w-36 bottom-6 left-6 z-[10]'>
                            <img loading='lazy' src={alhussniName} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesAlhussni
