import React from 'react'
import Header from './header/Header'
import Footer from './footer/Footer'
// import { Link } from 'react-scroll';
// import arrow from 'assets/icons/arrowWithLine.svg'
const Layout = ({ children }) => {
    return (
        <div className='relative con' >
            {/* this div for scroll to top */}
            {/* <div id="top"></div> */}
            <Header />
            {children}
            {/* <Link to="top" className='p-2 outline-1 outline outline-black -rotate-90 bg-primary-color fixed bottom-12 right-12 text-black z-[10000] w-12 h-12 flex items-center justify-center border rounded-full border-white cursor-pointer'><img src={arrow} alt="" /></Link> */}
            <Footer />
        </div>
    )
}

export default Layout
