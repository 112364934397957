import { settingsStore } from 'store/settingStore'
import { useTranslation } from 'react-i18next'

const BoxCompare = (props) => {
  const values = props.values;
  const bottle = values.bottles;
  const bgColor = props.bgColor;
  const BottonColor = props.BottonColor;
  const qr = values.qr;
  const direction = settingsStore((state) => state.direction);
  const { t } = useTranslation('index')

  return (
    <div className={`bg-${bgColor} overflow-hidden relative px-1 py-8 md:px-4 flex flex-col items-center justify-center border rounded-2xl border-primary-color shadow-[1px_4px_32px_0px_#21B1E542] max-sm:rounded-3xl w-full mx-auto `} >
      <div className={`absolute -top-2 -right-2 w-28 h-12 flex items-center font-bold justify-center bg-${BottonColor} rounded-2xl text-${bgColor}`}>{t(props.title)}</div>
      <div><img className='w-[150px] h-[200px] object-contain' loading='lazy' src={props.src} alt="" /></div>
      <div className=' grid grid-cols-2 justify-center items-center w-full my-6' >
        <div className='relative flex justify-evenly flex-col items-center font-cocon'>
          {bottle.map((item, index) => (
            <div className='flex justify-evenly items-center w-full mt-4 mb-4' key={index} >
              <div className='font-bold'>{item.bottles} {t('Bottles')}</div>
            </div>
          ))}
          <div className={`${direction === 'ltr' ? 'right-0' : 'left-0'} absolute  top-6 w-[1px] h-[100%] bg-gradient-to-b from-[rgba(0,150,158,0.5)] via-[rgba(0,150,158,0.2)] to-[rgba(255,255,255,0)]`}></div>
        </div>
        <div className='flex flex-col justify-evenly items-center text-center h-full font-cocon'>
          {qr.map((item, index) => (
            <div className=' w-full mt-4 mb-4 flex items-center justify-center' key={index} >
              <div className='font-bold'>{item.qr} {t('QR')}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={`text-${bgColor} w-full  bg-${BottonColor} py-2 flex justify-center items-center rounded-lg my-6 font-bold `}>
        {t(props.title)}
      </div>
    </div>
  )
}

export default BoxCompare

// box-shadow: 1px 4px 32px 0px #21B1E542;
// border: 1px solid;
// background: linear-gradient(270deg, #21B1E5 2.3%, rgba(33, 177, 229, 0) 100%);
// background: linear-gradient(270deg,#21B1E5_2.3%,rgba(33,177,229,0)100%);

// border-image-source: linear-gradient(270deg, #21B1E5 2.3%, rgba(33, 177, 229, 0) 100%);






