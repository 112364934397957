import React from "react";
import img1 from "assets/images/imagesFurniture/fernetureHeroImg1.webp";
import img2 from "assets/images/imagesFurniture/fernetureHeroImg2.webp";
import logo from "assets/icons/qatarLogo.svg";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation("index");

  return (
    <div className=" w-full flex max-md:flex-col gap-2 px-8 xl:px-28 ">
      <div className=" md:w-2/5  flex items-start">
        <div className="max-md:w-2/3">
          <img className="" loading="lazy" src={img1} alt="" />
        </div>
        <img className="max-md:w-1/3 md:hidden mt-20 p-6" src={logo} alt="" />
      </div>
      <div className="w-full max-md:my-4 md:w-1/5 flex justify-center items-center font-[400] tracking-wide md:mx-2  md:text-end text-[40px] sm:text-[80px] md:text-hug">
        <h1 className="flex flex-col max-md:mx-28 max-sm:mx-16 w-full">
          <span
            className="text-start"
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            {" "}
            {t("Create")}
          </span>
          <span
            className="text-end"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            {t("your")}
          </span>
          <span
            className="text-start"
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            {t("interior")}
          </span>
          <span
            className="text-end"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            {t("space")}.
          </span>
        </h1>
      </div>
      <div className="w-full md:w-2/5">
        <img className="w-3/5 max-md:hidden p-6" src={logo} alt="" />
        <div className="">
          <img className="" loading="lazy" src={img2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
