import React from 'react'
import img1 from 'assets/images/imagesFurniture/fernetureImg7.webp'
import img2 from 'assets/images/imagesFurniture/fernetureImg8.webp'
import img3 from 'assets/images/imagesFurniture/fernetureImg9.webp'
import img4 from 'assets/images/imagesFurniture/fernetureImg10.webp'
import img5 from 'assets/images/imagesFurniture/fernetureImg11.webp'
import img6 from 'assets/images/imagesFurniture/fernetureImg12.webp'
import img7 from 'assets/images/imagesFurniture/fernetureImg13.webp'
import img8 from 'assets/images/imagesFurniture/fernetureImg14.webp'
import img9 from 'assets/images/imagesFurniture/fernetureImg15.webp'
import { useTranslation } from 'react-i18next';


const FuniroGallary = () => {
    const { t } = useTranslation('index')

    return (
        <div className='flex flex-col'>
            <div className='flex flex-col items-center text-center justify-center mt-8 px-8 md:px-20'>
                <div className='text-large font-[400] tracking-[0.2rem] sm:tracking-[0.5rem]'>{t("Share your setup with")}</div>
                <h1 className='text-large tracking-wider font-[700]'>#FuniroFurniture</h1>
            </div>
            <div className='w-full flex gap-2 justify-center max-sm:p-4 max-sm:flex-col'>
                <div className='sm:w-2/5 flex flex-col gap-2 items-end'>
                    <div className='flex items-end gap-2'>
                        <div className='overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='fade-down' data-aos-delay='300' data-aos-duration='1000' src={img1} alt="" /></div>
                        <div className='overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='fade-down' data-aos-delay='600' data-aos-duration='1000' src={img2} alt="" /></div>
                    </div>
                    <div className='flex items-start gap-2'>
                        <div className='overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='fade-up' data-aos-delay='300' data-aos-duration='1000' src={img3} alt="" /></div>
                        <div className='overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='fade-up' data-aos-delay='600' data-aos-duration='1000' src={img4} alt="" /></div>
                    </div>

                </div>
                <div className='sm:w-1/5 flex items-center justify-center'>
                    <div className='w-full overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='zoom-in' data-aos-delay='900' data-aos-duration='1000' src={img5} alt="" /></div>
                </div>
                <div className='sm:w-2/5 flex flex-col gap-2 items-start'>
                    <div className='flex items-end gap-2'>
                        <div className='overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='fade-down' data-aos-delay='1200' data-aos-duration='1000' src={img6} alt="" /></div>
                        <div className='overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='fade-down' data-aos-delay='1500' data-aos-duration='1000' src={img7} alt="" /></div>
                    </div>
                    <div className='flex items-start gap-2'>
                        <div className='overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='fade-up' data-aos-delay='1200' data-aos-duration='1000' src={img8} alt="" /></div>
                        <div className='overflow-hidden'><img className='hover:scale-[1.2] transition-all duration-1000' data-aos='fade-up' data-aos-delay='1500' data-aos-duration='1000' src={img9} alt="" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FuniroGallary
