import React from 'react'
import { useHomeLogic } from '../hooks/useHomeLogic'
import { useTranslation } from 'react-i18next';

const Partners = () => {
  const { partnersImgs } = useHomeLogic()
  const { t } = useTranslation('index')
  return (
    <div className='text-main'>
      <div data-aos="fade-down" data-aos-duration="500">
        {t("Relations built on trust")}
        <div className='text-xlarge font-bold'>
          {t("OUR PARTNERS")}
        </div>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center items-center max-sm:gap-10 gap-14 mt-8'>
        {/* i am taking data here from useHomeLogic by name partnersImgs */}
        {partnersImgs?.map((item, index) => (
          <div data-aos="fade-down"  data-aos-duration="500" data-aos-delay={100 * (index + 0.5)} key={index} className='w-full' >
            <img className='p-4' loading='lazy' src={item.src} alt="" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Partners
