import arrowIcon from 'assets/icons/arrowWithLine.svg';
import { useTranslation } from 'react-i18next';
import { settingsStore } from 'store/settingStore';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNaiaLogic } from '../hooks/useNaiaLogic';

const SliderNaiaPage = () => {
    const { DataSlider } = useNaiaLogic()
    const { t } = useTranslation('index')
    const direction = settingsStore((state) => state.direction);
    return (

        <Swiper navigation={{ nextEl: '.swiper-button-next' }} loop modules={[Navigation]} className="mySwiper naiaSlider mt-6 lg:mt-10">
            {/* i am taking data here from useNaiaLogic by name DataSlider*/}
            {DataSlider?.map((item, index) => (
                <SwiperSlide className='h-full max-sm:pt-6' key={index}>
                    <div className='grid grid-cols-2 gap-2 max-sm:gap-4 w-full sm:w-[90%] md:w-[80%] justify-center md:justify-evenly items-center'>
                        <div>
                            <img className='max-h-[320px] w-auto' loading='lazy' src={item.src} alt='' />
                        </div>
                        <div className='flex flex-col md:ms-4 text-start'>
                            <div className='font-[500] font-inter text-large mb-4 ' >{t(item.title)}</div>
                            <div className='text-meduim font-poppins'>{t(item.content)}</div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
            <div className={`${direction === 'ltr' ? '' : 'rotate'} swiper-button-next after:hidden p-1 before:absolute before:w-[2px] md:before:w-[1px] before:h-8 max-md:before:opacity-[0.4] md:before:h-20 before:bg-black before:md:left-[-20px] before:left-[-10px] `}><img className='w-6 md:w-12 lg:w-14' alt='' src={arrowIcon} /></div>
        </Swiper>
    );
};

export default SliderNaiaPage;