import { Outlet, Route, Routes } from 'react-router-dom';
import './style/output.css'
import Layout from 'layout/Layout';
import Home from 'pages/Home/Home';
import Naia from 'pages/naia/Naia';
import Alhussni from 'pages/alhussni/Alhussni';
import Furniture from 'pages/ferneture/Furniture';
import Nitcomax from 'pages/nitcomax/Nitcomax';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
function App() {
  useEffect(() => { AOS.init(); }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Routes>
      <Route
        element={
          <Layout>
            <Outlet />
          </Layout>
        } >
        <Route path='/' element={<Home />} />
        <Route path='/naia' element={<Naia />} />
        <Route path='/alhussni' element={<Alhussni />} />
        <Route path='/furniture' element={<Furniture />} />
        <Route path='/nitcomax' element={<Nitcomax />} />


      </Route>
      <Route path='*' element={
        <p>
          not found

        </p>
      } />
    </Routes>
  );
}

export default App;
