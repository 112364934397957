import img1Slider from 'assets/images/imagesFurniture/furnitureSliderImg1.webp'
import img2Slider from 'assets/images/imagesFurniture/furnitureSliderImg2.webp'
import img3Slider from 'assets/images/imagesFurniture/furnitureSliderImg3.webp'
import img4Slider from 'assets/images/imagesFurniture/furnitureSliderImg2.webp'
import img5Slider from 'assets/images/imagesFurniture/furnitureSliderImg3.webp'

export const useFurnitureLogic = () => {
    const ImagesSlider = [
        { src: img1Slider },
        { src: img2Slider },
        { src: img3Slider },
        { src: img4Slider },
        { src: img5Slider },
    ]

    return {
        ImagesSlider
    }
}