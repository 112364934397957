import { useTranslation } from 'react-i18next'
import { useNaiaLogic } from '../hooks/useNaiaLogic'
import { settingsStore } from 'store/settingStore';
const BottlingPlaces = () => {
    const { bottling } = useNaiaLogic()
    const direction = settingsStore((state) => state.direction);
    const { t } = useTranslation('index')
    return (
        <div className='lg:ps-28 lg:pt-20 lg:pb-20 flex max-md:flex-col  items-center'>
            <div className='py-[30px] max-lg:p-8 md:w-4/5 lg:w-2/5 max-lg:text-start flex justify-cente flex-col lg:me-16' data-aos={`fade-${direction === 'ltr' ? 'right' : 'left'}`} data-aos-duration="1000" data-aos-delay="500">
                <h2 className='text-primary-naia-color font-[400] text-[30px] sm:text-xlarge font-bebas'>{t('BOTTLING IMPACTFUL MESSAGES FOR')}</h2>
                <div className='sm:leading-[50px] text-meduim mt-3 font-poppins'>{t('BOTTLING IMPACTFUL content')}</div>
            </div>
            <div className='py-20 lg:py-36 bg-[#D9D9D94F] max-lg:w-full max-lg:justify-center flex items-center'>
                <div className='grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-1 lg:h-[400px] '>
                    {/* i am taking data here from useNaiaLogic by name bottling*/}
                    {bottling?.map((item, index) => (
                        <div className='h-full relative overflow-hidden bottlingPlacesImg' data-aos="fade-down" data-aos-duration="1000" data-aos-delay={100 * (index + 1)} key={index}>
                            <div className='h-full'>
                                <img className='h-full' loading='lazy' src={item.src} alt="" />
                            </div>
                            <div className='font-bold max-md:text-xlarge p-3 text-meduim text-center absolute z-10 bottom-0 left-0 w-full h-full flex items-center justify-center translate-y-full text-white bg-gradient-to-t from-black-rgba to-transparent bg-opacity-40 uppercase'>{t(item.title)}</div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}

export default BottlingPlaces
