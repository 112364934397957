import { useTranslation } from "react-i18next";
import { settingsStore } from "store/settingStore";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import alhussniName from "../../../assets/icons/alhussniName.svg";
import { uesAlhussniLogic } from "../hooks/useAlhossniLogic";

const Hero = () => {
  const { t } = useTranslation("index");
  const { HeroSliderData } = uesAlhussniLogic();
  const direction = settingsStore((state) => state.direction);

  return (
    <div className="w-full">
      <div className="w-full relative">
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          loop
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Autoplay, Pagination]}
          className="w-full relative bg-transparent alhussni"
        >
          <div className="relative z-10  bg-transparent">
            {/* here i am taking data from uesAlhussniLogic by name HeroSliderData */}
            {HeroSliderData.map((item, index) => (
              <SwiperSlide
                className=" pb-16 lg:py-10 xl:py-0 px-8 xl:px-28 bg-transparent "
                key={index}
              >
                <div className="absolute inset-0 z-[-1] bg-black opacity-90"></div>

                <div
                  className={`${
                    direction === "ltr" ? "left-40" : "right-40"
                  } max-md:hidden absolute z-[-10] bg-black opacity-20 top-0 bottom-0  w-1/2`}
                ></div>
                <div
                  className={`${
                    direction === "ltr" ? "left-0" : "right-0"
                  } max-md:hidden absolute z-[-100] bg-black opacity-10 bottom-40 h-48 w-40`}
                ></div>
                <div className="flex max-md:flex-col-reverse items-center max-md:h-[100vh] 2xl:mb-[10vh] max-2xl:h-[90vh] 2xl:h-[auto] md:mt-[10vh] justify-around sm:justify-between bg-transparent">
                  <div className=" md:w-1/3 me-4 text-white text-start  flex flex-col font-Gallient">
                    <div>
                      <div className="max-w-[300px] ">
                        <img loading="lazy" src={alhussniName} alt="" />
                      </div>
                      <div className="my-4">
                        <h1 className="font-[400] lg:leading-[84.3px] text-[40px] lg:text-[60px] my-4 font-french">
                          {t(item.text1)}
                        </h1>
                        <div className="text-main">{t(item.text2)}</div>
                      </div>
                    </div>
                    <div className="text-main leading-[45px] flex flex-col">
                      <span>{t(item.PERFUME)}</span>
                      {t(item.text3)}
                    </div>
                  </div>
                  <div className="sm:p-8 w-1/1 max-md:h-[100%] max-sm:flex max-sm:items-center">
                    <img
                      className="md:w-[600px]"
                      loading="lazy"
                      src={item.src}
                      alt=""
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default Hero;
