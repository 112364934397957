import { useState } from "react";
import { useTranslation } from "react-i18next";
import { settingsStore } from "store/settingStore";
import logo from "../../assets/icons/businessHupLogo.svg";
import borderImg from "../../assets/images/border.png";
import mapImg from "../../assets/images/map.webp";
import axios from "axios";
const Contact = (props) => {
  const { t } = useTranslation("index");
  const direction = settingsStore((state) => state.direction);
  const [data, setData] = useState({});
  // const [validate, setValidate] = useState(false);
  const handleDataChange = (event) => {
    const { name, value } = event.target;
    setData((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailTest.test(email);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  const correctData = () => {
    if (
      validatePhoneNumber(data.phone) &&
      validateEmail(data.email) &&
      data.message !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const sendData = () => {
    try {
      if (correctData()) {
        axios
          .post("https://formsubmit.co/ajax/info@businesshub.com.qa", data)
          .then((response) => console.log(response));
        alert("thanks");
        console.log(data);
        setData({
          phone: "",
          email: "",
          message: "",
        });
      } else {
        alert("phone number or email is not correct");
      }
    } catch (error) {
      alert(error);
    }
  };
  return (
    <>
      <div className=" max-md:hidden p-10 xl:p-40 relative">
        <div className="relative z-0">
          <img src={mapImg} alt="" />
        </div>
        <div
          className={`${
            direction === "ltr"
              ? "left-[10%] xl:left-[20%] rounded-tr-[150px] rounded-bl-[150px]"
              : "right-[10%] xl:right-[20%] rounded-tl-[150px] rounded-br-[150px]"
          } absolute z-1 top-[10%] bottom-[10%] xl:top-[20%] xl:bottom-[20%]  w-[50%] lg:w-[35%] bg-primary-color justify-center items-center`}
          data-aos="zoom-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        ></div>
        <div
          className={`${
            direction === "ltr"
              ? "left-[15%] lg:left-[10%] xl:left-[20%]"
              : "right-[15%] lg:right-[10%] xl:right-[20%]"
          } absolute z-10 top-[10%] bottom-[10%] xl:top-[20%] xl:bottom-[20%]  flex flex-col w-[37%] justify-center items-center`}
          data-aos="zoom-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div className="absolute top-[8%] left-[10%] lg:left-[20%] font-bold text-meduim xl:text-large rounded-tl-[50px] rounded-br-[50px] text-white bg-black py-2 px-12">
            {t(props.title)}
          </div>
          {/* <form className='flex flex-col items-end'> */}
          <div className="flex flex-col items-end">
            <input
              placeholder={t("Phone No.")}
              name="phone"
              onChange={handleDataChange}
              value={data.phone}
              type="text"
              className="border border-spacing-2 bg-transparent rounded-2xl px-2 xl:px-12  py-2 xl:py-4 text-white placeholder-white outline-0"
            />
            <input
              placeholder={t("E-mail")}
              name="email"
              onChange={handleDataChange}
              value={data.email}
              type="text"
              className=" my-4 border border-spacing-2 bg-transparent rounded-2xl px-2 xl:px-12  py-2 xl:py-4 text-white placeholder-white outline-0"
            />
            <input
              placeholder={t("Message")}
              name="message"
              onChange={handleDataChange}
              value={data.message}
              type="text"
              className="border border-spacing-2 bg-transparent rounded-2xl  px-2 xl:px-12  py-2 xl:py-4 text-white placeholder-white outline-0"
            />
            <button
              disabled={!correctData()}
              className={`bg-white  flex justify-center items-center w-20 mt-[-20px] rounded-xl py-1 me-[-40px] text-primary-color`}
              onClick={sendData}
            >
              {t("Send")}
            </button>
            {/* </form> */}
          </div>
        </div>
        <div
          className={`${
            direction === "ltr" ? "" : "rotate"
          }  absolute z-2 inset-0 flex justify-center items-center`}
        >
          <img
            className=" w-[70%] xl:w-[50%] xl:h-auto "
            src={borderImg}
            alt=""
          />
        </div>
        <div
          className={`${
            direction === "ltr"
              ? "right-[7%] xl:right-[17%] "
              : "left-[7%] xl:left-[17%] "
          } md:absolute top-[10%] bottom-[10%] xl:top-[20%] xl:bottom-[20%] z-20 flex flex-col justify-between max-lg:w-[28%] w-[25%] text-[15px]`}
        >
          <div className="mx-auto max-lg:my-10 w-48 bg-white ">
            <img src={logo} alt="" />
          </div>
          <div
            className="bg-primary-color flex flex-col rounded-2xl p-4 w-full text-white"
            data-aos="zoom-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <div className="font-medium text-meduim">{t("Address")}</div>
            <div>
              <div className="font-[700]">
                {t("Company")} : <span className="font-normal">{t("loc")}</span>
              </div>
              <div className="font-[700]  my-2">
                {t("Phone")} :{" "}
                <a href="tel:+974 66688 584" className="font-normal text-white">
                  +974 66688 584
                </a>
              </div>
              <div className="font-[700] ">
                {t("Email")} :{" "}
                <a
                  href="mailto:info@businesshub.com.qa"
                  className="font-normal text-white"
                >
                  {" "}
                  info@businesshub.com.qa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact relative flex flex-col md:hidden mb-20 ">
        <div
          className="borderForContactMobile w-full"
          data-aos="zoom-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div className="relative max-sm:mt-10 mt-10 mx-auto max-sm:h-[400px] h-[550px] max-sm:w-[90%] w-[70%] round flex flex-col justify-center items-center bg-primary-color ">
            <div className="absolute top-[-12%] font-bold text-meduim xl:text-large rounded-tl-[50px] rounded-br-[50px] text-white bg-black py-2 px-12">
              {t(props.title)}
            </div>
            <form action="#" className="flex flex-col items-end">
              <input
                placeholder={t("Phone No.")}
                type="text"
                className="border border-spacing-2 bg-transparent rounded-2xl px-2 xl:px-12  py-2 xl:py-4 text-white placeholder-white outline-0"
              />
              <input
                placeholder={t("E-mail")}
                type="text"
                className=" my-4 border border-spacing-2 bg-transparent rounded-2xl px-2 xl:px-12  py-2 xl:py-4 text-white placeholder-white outline-0"
              />
              <input
                placeholder={t("Message")}
                type="text"
                className="border border-spacing-2 bg-transparent rounded-2xl  px-2 xl:px-12  py-2 xl:py-4 text-white placeholder-white outline-0"
              />
              <button
                disabled={!correctData()}
                className={`bg-white  flex justify-center items-center w-20 max-sm:mt-[-10px] mt-[-20px] rounded-xl py-1 max-sm:me-[-20px] me-[-40px] text-primary-color`}
                onClick={sendData}
              >
                {t("Send")}
              </button>
            </form>
          </div>
        </div>
        <div
          className="relative w-[80%] mx-auto translate-y-5 z-100 flex flex-col justify-between h-full"
          data-aos="zoom-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div className="mx-auto max-lg:my-10 w-48 bg-white ">
            <img src={logo} alt="" />
          </div>
          <div className="bg-primary-color flex flex-col rounded-2xl p-8 text-white">
            <div className="font-medium text-meduim">{t("Address")}</div>
            <div>
              <div className="font-[700] text-main">
                {t("Company")} : <span className="font-normal">{t("loc")}</span>
              </div>
              <div className="font-[700] text-main my-2">
                {t("Phone")} :{" "}
                <a href="tel:+97466688584" className="font-normal text-white">
                  +974 66688 584
                </a>
              </div>
              <div className="font-[700] text-main">
                {t("Email")} :{" "}
                <a
                  href="mailto:info@businesshub.com.qa"
                  className="font-normal text-white"
                >
                  {" "}
                  info@businesshub.com.qa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
