import React from 'react'
import { uesAlhussniLogic } from '../hooks/useAlhossniLogic'
import { useTranslation } from 'react-i18next';

const PerfumeBox = () => {
    const { perfume } = uesAlhussniLogic()
    const { t } = useTranslation('index')
    return (
        <div className=''>
            {/* i am taking data here from uesAlhussniLogic by name perfume*/}
            {perfume?.map((item, index) => (
                <div className={`${index % 2 === 0 ? 'sm:flex-row' : 'sm:flex-row-reverse'} flex max-sm:flex-col-reverse justify-center gap-4 items-center my-12 max-sm:border max-sm:p-8`} data-aos={`fade-${index === 1 ? 'right' : 'left'}`} data-aos-delay={300 * (index + 1)} data-aos-duration="1000" key={index}>
                    <p className='sm:w-1/3 max-lg:text-[12px] text-center'>{t(item.content)}</p>
                    <div className={`flex max-sm:flex-col-reverse justify-center items-center sm:w-2/3 ${index % 2 === 0 ? 'sm:border-t sm:border-e sm:border-b' : 'sm:border-t sm:border-s sm:border-b'} `}>
                        <div className='sm:w-1/2'><img className='lg:w-[80%] max-sm:w-[80%] mx-auto' loading='lazy' src={item.src} alt="" /></div>
                        <div className='w-full sm:w-1/2 text-center text-[20px] sm:text-large font-Cinzel'>{t(item.title)}</div>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default PerfumeBox
