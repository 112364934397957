import heroLogo1 from 'assets/icons/iconsNitcomax/logoHeroNitcomax1.svg'
import heroLogo2 from 'assets/icons/iconsNitcomax/logoHeroNitcomax2.svg'
import heroLogo3 from 'assets/icons/iconsNitcomax/logoHeroNitcomax3.svg'
import heroLogo4 from 'assets/icons/iconsNitcomax/logoHeroNitcomax4.svg'
import heroLogo5 from 'assets/icons/iconsNitcomax/logoHeroNitcomax5.svg'
import ImgFAQ1 from 'assets/images/imagesNitcomax/ImgFAQ1.webp'
import ImgFAQ2 from 'assets/images/imagesNitcomax/ImgFAQ2.webp'
import ImgFAQ3 from 'assets/images/imagesNitcomax/ImgFAQ3.webp'
import ImgFAQ4 from 'assets/images/imagesNitcomax/ImgFAQ4.webp'

export const useNitcomaxLogic = () => {
    const listNumbers = [
        {
            num: '12K+',
            title: 'Project Complete'
        },
        {
            num: '7K+',
            title: 'Happy Client'
        },
        {
            num: '10+',
            title: 'Years Experience'
        },
        {
            num: '270+',
            title: 'Win Awards'
        },
    ];

    const FAQList = [
        {
            num: '01',
            title: 'Web & Mobile Development',
            src: ImgFAQ1
        },
        {
            num: '02',
            title: 'Interaction Design',
            src: ImgFAQ2
        },
        {
            num: '03',
            title: 'Digital Marketing',
            src: ImgFAQ3
        },
        {
            num: '04',
            title: 'Branding & Strategy',
            src: ImgFAQ4
        },
    ];
    const logosHero = [
        { src: heroLogo1 },
        { src: heroLogo2 },
        { src: heroLogo3 },
        { src: heroLogo4 },
        { src: heroLogo5 },
    ]


    return {
        listNumbers, FAQList, logosHero
    }
}

