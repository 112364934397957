import busnissHupLogo from 'assets/images/imagesHome/hup.png';
import chatIcon from 'assets/icons/chat.png';
import menuIcon from 'assets/icons/menuIcon.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll'
import { menuitems } from 'static.js';
import { settingsStore } from 'store/settingStore';
import 'style/input.css';

const Header = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation('navbar')
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [direction, setDirection] = settingsStore((state) => [
        state.direction, state.setDirection
    ])
    // function for change direction
    const switchLanguage = () => {
        setDirection(direction === "ltr" ? "rtl" : "ltr")
        i18n.changeLanguage(direction === "ltr" ? "ar" : "en");
    };
    const currentLanguage = i18n.language;

    // function for change language
    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'ar' : 'en';
        switchLanguage(newLanguage);
    };
    // function for menu open and close
    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    // function for navigate to another page when click on links
    const handleLinkClick = (src) => {
        navigate(src);
        setIsMenuOpen(false);
    }
    return (
        <div  className='font-montserrat relative flex justify-between items-center border-b border-[#BBBBBB]' id='head'>
            <a className='w-[200px]' href='/'>
                <img src={busnissHupLogo} alt="" />
            </a>
            <div className='md:w-[80%] flex justify-between'>
                <ul className={`flex max-md:flex-col ${isMenuOpen ? ' max-md:opacity-1 max-md:h-[250px]' : 'max-md:h-0 max-md:opacity-0'} ${direction === 'ltr' ? 'max-md:right-2 rounded-tl-3xl' : 'max-md:left-2 rounded-tr-3xl'}  flex-1 max-md:w-1/3 max-sm:w-2/3 text-start justify-evenly items-center font-medium text-[16px] md:text-[14px] lg:text-[16px] max-md:absolute max-md:top-[110%]  max-md:items-start max-md:p-4 max-md:z-10 max-md:bg-white  rounded-br-3xl rounded-bl-3xl shadowForMenu`}>
                    {/* i am taking data here from file static.js */}
                    {menuitems.map((item,index) => (
                        <li key={index} className={`cursor-pointer ${isMenuOpen ? 'max-md:block' : 'max-md:hidden'} max-md:my-1 hover:text-primary-color`} onClick={() => handleLinkClick(item.src)}>{t(item.name)}</li>
                    ))}
                    <Link to='about'className={`cursor-pointer ${isMenuOpen ? 'max-md:block' : 'max-md:hidden'} max-md:my-1 hover:text-primary-color`} >{t('About us')}</Link>
                </ul>
                <Link to="contact" className='cursor-pointer hover:opacity-50 bg-primary-color flex items-center justify-around px-3 py-2 rounded-lg w-auto max-lg:hidden'>
                    <div className='text-md font-bold me-2 text-[#fff]' >{t('Contact')}</div>
                    <div>
                        <img className='w-6' src={chatIcon} alt="" />
                    </div>
                </Link>
            </div>
            <div className='h-20 mx-4 w-[1px] bg-[#000] max-lg:hidden'>
            </div>
            <div className='me-4 flex items-center justify-around'>
                <div
                    className='font-medium text-[20px] ms-2 cursor-pointer hover:text-primary-color'
                    onClick={toggleLanguage}>
                    {currentLanguage === 'en' ? t('ar') : t('en')}
                </div>
                <div className='md:hidden max-md:w-8 flex justify-center items-center cursor-pointer max-md:ms-4' onClick={handleMenuClick}>
                    {isMenuOpen && <div className='font-bold text-xlarge text-primary-color'>x</div>}
                    {!isMenuOpen && <img src={menuIcon} alt="" />}
                </div>
            </div>

        </div >
    )
}

export default Header
