import InstagramIcons from 'components/styled/InstagramIcon';
// import TikTokIcon from 'components/styled/TiktokIcon'
// import LinkedinIcon from 'components/styled/LinkedinIcon';
export const SocialMediaIcons = ({ fill, color }) => [
    {
        src: <InstagramIcons fill={fill} color={color}/>
    },
    // {
    //     src: <TikTokIcon fill={fill} />
    // },
    // {
    //     src: <LinkedinIcon fill={fill} color={color} />
    // }
];
export const menuitems = [
    {
        name: "Home",
        src: "/",
    },
    {
        name: "Naia Water",
        src: "/naia",
    },
    {
        name: "Qatar Online Furniture",
        src: "/furniture",
    },
    {
        name: "AlHussni Perfume",
        src: "/alhussni",
    },
    {
        name: "Nitcomax",
        src: "/nitcomax",
    },
]
