import fragrancesImg1 from 'assets/icons/iconsAlhussni/jasmine.svg'
import fragrancesImg2 from 'assets/icons/iconsAlhussni/violet.svg'
import fragrancesImg3 from 'assets/icons/iconsAlhussni/vanilla.svg'
import fragrancesImg4 from 'assets/icons/iconsAlhussni/basil.svg'
import fragrancesImg5 from 'assets/icons/iconsAlhussni/oud.svg'
import fragrancesImg6 from 'assets/icons/iconsAlhussni/citrus.svg'
import fragrancesImg7 from 'assets/icons/iconsAlhussni/sage.svg'
import fragrancesImg8 from 'assets/icons/iconsAlhussni/levander.svg'
import perfumeImg1 from 'assets/images/imagesAlhussni/perfume1.webp'
import perfumeImg2 from 'assets/images/imagesAlhussni/perfume2.webp'
import perfumeImg3 from 'assets/images/imagesAlhussni/perfume3.png'
import imgHero1 from 'assets/images/imagesAlhussni/alhussniHeroImg1.webp'
import imgHero2 from 'assets/images/imagesAlhussni/AlhussniHeroImg2.webp'
import imgHero3 from 'assets/images/imagesAlhussni/AlhussniHeroImg3.webp'
import imgHero4 from 'assets/images/imagesAlhussni/AlhussniHeroImg4.webp'

export const uesAlhussniLogic = () => {
    const HeroSliderData = [
        {
            src: imgHero1,
            text1: 'Perfumes de la mode',
            text2: 'Experience the Italian flair',
            PERFUME:'PERFUME',
            text3: 'IS A STORY IN ODOR, SOMETIMES POETrY IN MEMOrY'
        },
        {
            src: imgHero2,
            text1: 'Perfumes de la mode',
            text2: 'Experience the Italian flair',
            PERFUME:'PERFUME',
            text3: 'IS A STORY IN ODOR, SOMETIMES POETrY IN MEMOrY'
        },
        {
            src: imgHero3,
            text1: 'Perfumes de la mode',
            text2: 'Experience the Italian flair',
            PERFUME:'PERFUME',
            text3: 'IS A STORY IN ODOR, SOMETIMES POETrY IN MEMOrY'
        },
        {
            src: imgHero4,
            text1: 'Perfumes de la mode',
            text2: 'Experience the Italian flair',
            PERFUME:'PERFUME',
            text3: 'IS A STORY IN ODOR, SOMETIMES POETrY IN MEMOrY'
        }
    ]
    const list = [
        {
            src: fragrancesImg1,
            title: 'Jasmine'
        },
        {
            src: fragrancesImg2,
            title: 'Violet'
        },
        {
            src: fragrancesImg3,
            title: 'Vanilla'
        },
        {
            src: fragrancesImg4,
            title: 'Basil'
        },
        {
            src: fragrancesImg5,
            title: 'Oud'
        },
        {
            src: fragrancesImg6,
            title: 'Citrus'
        },
        {
            src: fragrancesImg7,
            title: 'Sage'
        },
        {
            src: fragrancesImg8,
            title: 'Lavander'
        },
    ]

    const perfume = [
        {
            src: perfumeImg1,
            title: 'Perfume',
            content: 'text1'
        },
        {
            src: perfumeImg2,
            title: 'PACKAGING',
            content: 'text2'
        },
        {
            src: perfumeImg3,
            title: 'PACKAGING',
            content: 'text3'
        },
    ]

    return {
        list, perfume, HeroSliderData
    }
}