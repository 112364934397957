import { useTranslation } from 'react-i18next'

const DistributionPlaces = (props) => {
    const { t } = useTranslation('index')

    return (
        <div className='flex flex-wrap justify-center items-center '>
            {/* i am taking data here from props because this component is general only in this page*/}
            {props.values.map((item, index) => (
                <div data-aos="zoom-in" data-aos-duration='400' data-aos-delay="300" key={index} className=' flex flex-col px-1 w-1/3 md:w-1/4'>
                    <div className='flex justify-center items-center'>
                        <img className='w-[100px] h-[200px]' loading='lazy' src={item.src} alt="" />
                    </div>
                    <div className='text-main font-inter font-[700] text-center max-h-1/5 mt-1'>
                        {t(item.title)}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default DistributionPlaces
