import React from 'react'
import arrowIcon from 'assets/icons/arrowWithLine.svg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { settingsStore } from 'store/settingStore';
import { useHomeLogic } from '../hooks/useHomeLogic';

const Services = () => {
    const navigate = useNavigate()
    const { service } = useHomeLogic()
    const direction = settingsStore((state) => state.direction);
    const { t } = useTranslation('index')
    return (
        <div className='flex flex-col justify-center items-center'>
            <div className='text-xlarge font-cocon text-primary-color font-bold'>{t("Services Companies")}</div>
            <div className='grid grid-cols-1 grid-rows-4 sm:grid-cols-2 sm:grid-rows-2 gap-4 my-8 max-sm:mx-12 max-w-[550px]'>
                {/* i am taking data here from useHomeLogic by name service */}
                {service?.map((item, index) =>
                    <div data-aos='zoom-in' data-aos-delay='300' data-aos-duration='1000' key={index} className='overflow-hidden cursor-pointer rounded-[70px] border-4 border-primary-color flex flex-col justify-center relative p-12'  onClick={() => (navigate(item.src))}>
                        <img className='' loading='lazy' src={item.img} alt="" />
                        <div className={` ${direction === "ltr" ? "right-5" : "left-5 rotate"} absolute bottom-4  before:absolute before:left-[-15px] before:w-[1px] before:h-full before:bg-black before:z-10 `}><img className='w-16 sm:w-20' src={arrowIcon} alt="" /></div>
                    </div>
                )}
            </div>
        </div >
    )
}

export default Services