import Contact from "components/shared/Contact";
import homeSelling from "assets/images/imagesHome/homeSelling.webp";
import Advantages from "./components/Advantages";
import Carousel from "./components/SwitchImages";
import Partners from "./components/Partners";
import Services from "./components/Services";
import Hero from "./components/hero.jsx";
import List from "./components/List";
import { useTranslation } from "react-i18next";
import { useHomeLogic } from "./hooks/useHomeLogic";
import { Helmet } from "react-helmet";
const Home = () => {
  const { t } = useTranslation("index");
  const { Lists } = useHomeLogic();
  return (
    <div className="">
      <Helmet>
        <title>BusinessHub</title>
        <meta property="og:site_name" content="Businesshub" />
        <meta property="og:url" content="https://business-hub.r-link.io/" />
        <meta property="og:title" content="Businesshub" />
        <meta
          property="og:type"
          content="Business Hub from wholesale trading to essential services, all that your business needs here, explore more to unleash your business with all possible solutions"
        />
        <meta
          name="description"
          content="Business Hub from wholesale trading to essential services, all that your business needs here, explore more to unleash your business with all possible solutions"
        />
        <meta name="twitter:site" content="@Businesshub" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Businesshub" />
        <meta
          name="twitter:description"
          content="Business Hub from wholesale trading to essential services, all that your business needs here, explore more to unleash your business with all possible solutions"
        />
      </Helmet>
      <Hero />
      {/* Most Selling Products Section */}
      <div className="mostSelling flex flex-col p-8 sm:p-28 font-cocon">
        <div
          className=" text-hug font-[500] text-primary-color max-sm:text-center"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          {t("Most Selling Products")}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-sm:grid-rows-6 grid-rows-2 mt-4 gap-4">
          <div
            className=" rounded-lg shadowBrown"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <div className="flex justify-center items-center">
              <img
                className="w-[90%]"
                loading="lazy"
                src={homeSelling}
                alt=""
              />
            </div>
          </div>
          {/* i am taking data here from file usehomelogic.js by name is Lists and i pass data by props  */}
          {Lists.lists?.map((list, index) => (
            <div
              className={`shadowBrown ${index === 2 ? "row-start-2" : ""}`}
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay={200 * (index + 1)}
              data-aos-offset="100"
              key={index}
            >
              <List
                textColor={index === 2 ? "black" : "white"}
                bgcolor={index === 2 ? "bg-white" : "bg-[#778692]"}
                headerList={t(Lists?.headerList[index]?.header)}
                prop1={list}
              />
            </div>
          ))}
        </div>
      </div>
      {/* About Us Section */}
      <div className="font-cocon flex flex-col p-0 sm:p-28" id="about">
        <div
          className="text-xlarge font-[500] py-2 px-8 shadow-lg text-center max-sm:m-8 w-auto lg:w-1/3 rounded-lg"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-offset="200"
        >
          {t("About us")}
        </div>
        <div className=" flex flex-col-reverse justify-center items-center lg:grid md:gap-8 lg:grid-cols-5 sm:mt-12">
          <div className="xl:leading-[40px] text-[16] sm:text-[20px] font-inter lg:col-span-2 px-4 max-lg:mt-8 text-start">
            {t("home about us content")}
          </div>
          <div className="h-full w-full lg:col-span-3">
            <Carousel />
          </div>
        </div>
      </div>
      {/* Advantages Section */}
      <div className=" p-4 sm:p-28">
        <Advantages />
      </div>
      {/* Services Section */}
      <div className="p-4 sm:p-20">
        <Services />
      </div>
      {/* OUR PARTNERS Section*/}
      <div className="flex flex-col p-4 sm:p-28 font-inter">
        <Partners />
      </div>
      {/* Contact us Section this is in component shared */}
      <div id="contact">
        <Contact title="contact us" />
      </div>
    </div>
  );
};

export default Home;
