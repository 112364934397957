import arrowClose from 'assets/icons/iconsNitcomax/arrowarrowFAQ.svg';
import arrowOpen from 'assets/icons/iconsNitcomax/arrowarrowFAQOpen.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { settingsStore } from 'store/settingStore';
import { useNitcomaxLogic } from '../hooks/useNitcomaxLogic';

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const direction = settingsStore((state) => state.direction);
    const { t } = useTranslation('index')
    // here function for open and close FAQ by index
    const handleFAQList = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const { FAQList } = useNitcomaxLogic()
    return (
        <div className='lg:px-20 lg:py-10 p-8 font-poppins'>
            {/* i am taking data here from useNitcomaxLogic by name FAQList */}
            {FAQList?.map((item, index) => (
                <div className={`relative flex flex-col w-full transition-all duration-500 `} data-aos="fade-down" data-aos-delay='300' key={index} onClick={() => handleFAQList(index)}>
                    <div className=' flex items-center justify-between w-full cursor-pointer mt-12'>
                        <div className='flex items-start'>
                            <div className='me-3'>
                                {t(item.num)}
                            </div>
                            <div className='font-[600] text-large'>{t(item.title)}</div>
                        </div>
                        {openIndex === index ? (
                            <div className={`${direction === 'ltr' ? '' : 'rotate'} w-[30px] sm:w-[50px] ms-2`}>
                                <img src={arrowOpen} alt="" />
                            </div>
                        ) :
                            <div className={`${direction === 'ltr' ? '' : 'rotate'} w-[30px] sm:w-[50px] ms-2`}>
                                <img src={arrowClose} alt='' />
                            </div>}
                    </div>
                    {/* here image Appear when click on any FAQ element */}
                    {openIndex === index && (
                        <div className={`fadein md:absolute max-md:my-8 max-md:flex max-md:w-full max-md:justify-center  ${direction === 'ltr' ? 'right-16 lg:right-20 ' : 'rotate left-16 lg:left-20'} top-[-50%] lg:top-[-100%] bottom-0 z-10 transition-all duration-1000`}>
                            <img className='max-lg:w-[250px] w-[300px] ' loading='lazy' src={item.src} alt="" />
                        </div>
                    )}
                    <div className='h-[1px] mt-12 w-full bg-black '></div>
                </div>
            ))}
        </div >
    );
};

export default FAQ;
