import "aos/dist/aos.css";
import { useState } from "react";
import "style/input.css";
// import { useHomeLogic } from "../hooks/useHomeLogic";
// import img1 from 'assets/images/imagesHome/advantagesImg1.png';
import img1 from "assets/images/imagesHome/sliderBusinessHupImg1.jpg";
import img2 from "assets/images/imagesHome/sliderBusinessHupImg2.jpg";
import img3 from "assets/images/imagesHome/sliderBusinessHupImg3.jpg";
import img4 from "assets/images/imagesHome/sliderBusinessHupImg4.webp";
import img5 from "assets/images/imagesHome/sliderBusinessHupImg5.webp";
const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const imagesForCarousel = [
    { src: img5 },
    { src: img1 },
    { src: img2 },
    { src: img3 },
    { src: img4 },
  ];
  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="container mx-auto">
      <div className="flex max-lg:flex-col w-full">
        <div className="w-9/12 flex max-lg:w-full">
          <div className="w-full mx-auto overflow-hidden rounded-xl">
            <img
              src={
                !activeIndex
                  ? imagesForCarousel[0].src
                  : imagesForCarousel[activeIndex].src
              }
              key={activeIndex}
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-offset="200"
              alt=""
              className="fadein w-full h-full object-cover transition-all duration-300 "
            />
          </div>
        </div>
        <div className="lg:w-3/12 lg:ml-2 max-lg:mt-4 max-lg:flex max-sm:px-4 mx-auto lg:flex lg:flex-col justify-between items-center">
          {imagesForCarousel?.map((item, index) => (
            <div
              style={{
                paddingLeft: "0px",
              }}
              className=""
              key={index}
            >
              {/* index here for small images activeIndex here for big image now */}
              {index === activeIndex ? (
                ""
              ) : (
                <img
                  src={item.src}
                  onClick={() => handleThumbnailClick(index)}

                  alt="Gallery"
                  className="hover:scale-[1.1] transition-all duration-500 rounded-xl thumbnail-image w-[150px] max-sm:h-[70px] h-[100px] p-1 cursor-pointer"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
