import img1 from 'assets/images/imagesHome/sliderBusinessHupImg1.jpg';
import img2 from 'assets/images/imagesHome/sliderBusinessHupImg2.jpg';
import img3 from 'assets/images/imagesHome/sliderBusinessHupImg3.jpg';
import img4 from 'assets/images/imagesHome/sliderBusinessHupImg4.webp';
import img5 from 'assets/images/imagesHome/sliderBusinessHupImg5.webp';
import advantagesImg1 from 'assets/images/imagesHome/advantagesImg1.webp';
import advantagesImg2 from 'assets/images/imagesHome/advantagesImg2.webp';
import advantagesImg3 from 'assets/images/imagesHome/advantagesImg3.webp';
import advantagesImg4 from 'assets/images/imagesHome/advantagesImg4.webp';
import service1 from 'assets/icons/naiaLogo.svg';
import service2 from 'assets/icons/alhussniLogo.svg';
import service3 from 'assets/icons/qatarLogo.svg';
import service4 from 'assets/icons/nitcomaxlogo.svg';
import partner1 from 'assets/icons/iconsHome/partner1.svg';
import partner2 from 'assets/icons/iconsHome/partner2.svg';
import partner3 from 'assets/icons/iconsHome/partner3.svg';
import partner4 from 'assets/icons/iconsHome/partner4.svg';
import partner5 from 'assets/icons/iconsHome/partner5.svg';
import partner6 from 'assets/icons/iconsHome/partner6.svg';
import partner7 from 'assets/icons/iconsHome/partner7.svg';
import partner8 from 'assets/icons/iconsHome/partner8.svg';

export const useHomeLogic = () => {
    const Lists = {
        headerList: [
            { header: 'Stationery' },
            { header: 'Disposable Products' },
            { header: 'Health and Safety - Medical' },
            { header: 'Food & Beverages' },
            { header: 'Cleaning & Disinfecting Materials' }
        ],
        lists: [
            [
                { content: 'A4 & A3 Papers' },
                { content: 'All types of Toners (Original & Copy)' },
                { content: 'All types of Office Supplies' },
                { content: 'Office Furniture' },
                { content: 'All Electronic Equipment’s' }
            ],
            [
                { content: 'Facial Tissue' },
                { content: 'Maxi Roll' },
                { content: 'Toilet Roll' },
                { content: 'Auto cut Tissue' },
                { content: 'Cocktail Napkin' },
                { content: 'Sponge' },
                { content: 'CR-P2 Lithium Batteries' }
            ],
            [
                { content: 'Vinyl Gloves' },
                { content: 'Latex Gloves' },
                { content: 'Nitrile Gloves' },
                { content: 'Face Masks' },
                { content: 'First Aid Kit' },
                { content: 'Fire Extinguisher' },
                { content: 'Other Medical Supplies' }
            ],
            [
                { content: 'All types of Mineral Water' },
                { content: 'Soft Drinks' },
                { content: 'Tea’s & Coffee’s' },
                { content: 'Imported Food Products' },
                { content: 'Special Products – Halal & Diet' }
            ],
            [
                { content: 'Alcohol Disinfectant' },
                { content: 'Alcohol Wipes' },
                { content: 'Sanitizer Gel & Hand Soap' },
                { content: 'All Cleaning Materials for all surfaces' },
                { content: 'Air Fresheners' },
                { content: 'Hand Sanitizer' }
            ]
        ]
    };

    const imagesForCarousel = [
        { src: img5 },
        { src: img1 },
        { src: img2 },
        { src: img3 },
        { src: img4 },
    ]

    const advantagesBusinessHup = {
        imgs: [
            { src: advantagesImg1 },
            { src: advantagesImg2 },
            { src: advantagesImg3 },
            { src: advantagesImg4 },
        ],
        content: [
            {
                num: 1,
                text: 'home advantage1'
            },
            {
                num: 2,
                text: 'home advantage2'
            },
            {
                num: 3,
                text: 'home advantage3'
            },
            {
                num: 4,
                text: 'home advantage4'
            },
        ]
    }
    const service = [
        {
            img: service1,
            src: '/naia'
        },
        {
            img: service2,
            src: '/alhussni'
        },
        {
            img: service3,
            src: '/furniture'
        },
        {
            img: service4,
            src: '/nitcomax'
        },
    ]
    const partnersImgs = [
        { src: partner1 },
        { src: partner2 },
        { src: partner3 },
        { src: partner4 },
        { src: partner5 },
        { src: partner6 },
        { src: partner7 },
        { src: partner8 },
    ]

    return {
        Lists, imagesForCarousel, advantagesBusinessHup, service, partnersImgs
    }
}