import logo from 'assets/icons/nitcomaxlogo.svg'
import img from 'assets/images/imagesNitcomax/nitcomaxHeroImg.webp'
import { useTranslation } from 'react-i18next'
import { useNitcomaxLogic } from '../hooks/useNitcomaxLogic'

const Hero = () => {
    const { t } = useTranslation('index')
    const { listNumbers, logosHero } = useNitcomaxLogic()
    return (
        <div>
            <div className='w-full flex flex-col'>
                <div className='w-full md:grid md:grid-cols-7 flex flex-col-reverse md:grid-rows-1 '>
                    <div className='col-span-3 flex flex-col p-8 lg:p-20 justify-start '>
                        <div><img className='w-1/2' src={logo} alt="" /></div>
                        <div className='md:my-8 mb-8 flex max-md:justify-end'><h1 className='max-md:w-2/3 text-hug max-md:text-[50px] font-[500] font-poppins'>{t('Design')} {t("Agency")}</h1></div>
                        <div><p className='text-main xl:text-[20px] font-jakarta'>{t("nitcomax hero paragraph")}</p></div>
                    </div>
                    <div className='relative col-span-4 grid grid-cols-4'>
                        <div className='col-span-3 bg-[#222222]'><img loading='lazy' src={img} alt="" /></div>
                        <div className='bg-[#222222] p-2 sm:p-8'>
                            <div className='flex flex-col h-full justify-around'>
                                {/* i am taking data here from useNitcomaxLogic by name listNumbers */}
                                {listNumbers?.map((item, index) => (
                                    <div className='text-[12px] flex flex-col text-white font-jakarta' key={index}>
                                        <span className='text-large font-[600] font-poppins'>{item.num}</span>
                                        {t(item.title)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' flex justify-evenly items-center p-4 sm:p-12 bg-[#222222]' >
                    {logosHero?.map((item, index) => (
                        <div key={index}><img loading='lazy' className='max-sm:w-14 max-md:w-20 w-28' src={item.src} alt="" /></div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Hero
