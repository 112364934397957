// import AOS from "aos";
import "aos/dist/aos.css";
import bottleCan from "assets/images/imagesNaia/bottleCan.png";
import bottlePlastic from "assets/icons/iconsNaia/bottlePlastic.svg";
import bottleCarton from "assets/icons/iconsNaia/cartonImg.svg";
import hands from "assets/icons/iconsNaia/handsNaiaPage.svg";
import mapPlaces from "assets/images/imagesNaia/mapPlaces.webp";
import naiaImg from "assets/images/imagesNaia/naiaLogo.png";
import video from "assets/videos/naia.mp4";
import Contact from "components/shared/Contact";
// import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { settingsStore } from "store/settingStore";
import BottlingPlaces from "./components/BottlingPlaces";
import BoxCompare from "./components/BoxCompare";
import DistributionPlaces from "./components/DistributionPlaces";
import HowNaiaWorks from "./components/HowNaiaWorks";
import SliderNaiaPage from "./components/SliderNaiaPage";
import { useNaiaLogic } from "./hooks/useNaiaLogic";
const Naia = () => {
  const { distributionPlaces, compareValues } = useNaiaLogic();
  const plasticValues = compareValues.plastic;
  const canValues = compareValues.can;
  const cartonValues = compareValues.carton;

  const direction = settingsStore((state) => state.direction);
  const { t } = useTranslation("index");
  return (
    <div className="">
      <Helmet>
        <title>NaiaWater</title>
        <meta property="og:site_name" content="Naia Water" />
        <meta property="og:url" content="https://business-hub.r-link.io/naia" />
        <meta property="og:title" content="Naia Water" />
        <meta property="og:type" content="Naia water for sale bottles" />
        <meta
          name="description"
          content="Discover the new meaning of bottles for water, make your own message make your own ad with Naia Water today and be special"
        />
        <meta name="twitter:site" content="@Naia-Water" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Naia Water" />
        <meta
          name="twitter:description"
          content="Discover the new meaning of bottles for water, make your own message make your own ad with Naia Water today and be special"
        />
      </Helmet>
      <div>
        <video
          className="object-cover border-0 h-[550px] w-screen"
          loading="lazy"
          autoPlay
          src={video}
          loop
          muted
        />
      </div>
      <div className="p-8 md:p-20 lg:p-28 flex flex-col" id="about">
        <h2 className="text-primary-naia-color text-[30px] sm:text-xlarge font-[400] mb-4 font-bebas">
          {t("About Naia water")}
        </h2>
        <div className="text-md lg:text-xl font-poppins">
          <span className="text-primary-naia-color">
            {t("Welcome to Naia Water!")}
          </span>
          {t("naia about content")}
        </div>
      </div>
      {/* here BOTTLING IMPACTFUL section */}
      <div>
        <BottlingPlaces />
      </div>
      {/* here Why Choose Water Advertising? section */}
      <div className="p-4 pt-10 lg:p-28">
        <div>
          <h2
            className="font-[400] font-bebas text-large text-primary-naia-color flex items-center"
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="500"
            data-aos-anchor-placement="center-bottom"
          >
            {t("Why Choose")}
            <img
              className="w-[100px] h-[75px] lg:w-[180px] lg:h-[120px] inline-flex"
              loading="lazy"
              data-aos="flip-left"
              data-aos-delay="500"
              data-aos-duration="1000"
              src={naiaImg}
              alt=""
            />
            {t("Water Advertising?")}
          </h2>
        </div>
        <div>
          <SliderNaiaPage />
        </div>
      </div>
      {/* here How Water Works ? section */}
      <div className="p-4 pt-10 lg:p-28 flex flex-col">
        <div>
          {/* here for english */}
          <h2
            className={`${
              direction === "ltr" ? "" : "hidden"
            } flex items-center font-[400] font-bebas text-large text-primary-naia-color`}
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            {t("How")}
            <img
              className="w-[100px] h-[75px] lg:w-[180px] lg:h-[120px] inline-flex"
              loading="lazy"
              data-aos="flip-left"
              data-aos-duration="1000"
              data-aos-delay="500"
              src={naiaImg}
              alt=""
            />
            {t("Water")} {t("Works")} ?
          </h2>
          {/* here for arabic because  words order is changed */}
          <h2
            className={`${
              direction === "ltr" ? "hidden" : ""
            } flex items-center font-[400] font-bebas text-large text-primary-naia-color`}
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            {t("How")} {t("Works")} {t("Water")}
            <img
              className="w-[100px] h-[75px] lg:w-[180px] lg:h-[120px] inline-flex"
              loading="lazy"
              data-aos="flip-left"
              data-aos-duration="1000"
              data-aos-delay="500"
              src={naiaImg}
              alt=""
            />
            ؟
          </h2>
        </div>
        <div className="mt-4 grid gird-cols-1 md:grid-cols-3 gap-8 justify-center items-center md:items-end">
          <HowNaiaWorks />
        </div>
      </div>
      {/* here section products */}
      <div className="p-8 pt-12 lg:p-28">
        <h2 className="font-[500] font-cocon text-large text-center mb-8 ">
          {t("We offer 3 different types of Products")}
          <span className="text-primary-naia-color">
            {" "}
            {t("“Plastic & Can Water”")}
          </span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-10">
          <BoxCompare
            bgColor="[#61C5D8]"
            BottonColor="white"
            src={bottlePlastic}
            title="Plastic"
            values={plasticValues}
          />
          <BoxCompare
            bgColor="[#61C5D8]"
            BottonColor="white"
            src={bottleCan}
            title="Can"
            values={canValues}
          />
          <BoxCompare
            bgColor="white"
            BottonColor="[#61C5D8]"
            src={bottleCarton}
            title="Carton"
            values={cartonValues}
          />
        </div>
      </div>
      {/* here DistributionPlaces section */}
      <div className="flex justify-center p-8 lg:p-20">
        <DistributionPlaces values={distributionPlaces} />
      </div>
      {/* here DistributionPlaces section */}
      <div className="flex justify-start max-md:flex-col-reverse items-center mt-8 p-8 lg:p-28">
        <div className="w-full md:w-1/3 md:me-4">
          {distributionPlaces?.map((item, index) => (
            <div
              className="font-[700] font-inter text-meduim my-6 xl:my-8"
              key={index}
            >
              {t(item.title)}
            </div>
          ))}
        </div>
        <div className="w-full md:w-2/3">
          <img
            loading="lazy"
            data-aos="zoom-in"
            data-aos-delay="200"
            data-aos-duration="300"
            src={mapPlaces}
            alt=""
          />
        </div>
      </div>
      {/* here contact us section i am taking this section from shared conponents */}
      <div id="contact">
        <Contact title={t("To Donate")} />
      </div>
      {/* here Together We Make an Impact section it will be hidden in the mobile*/}
      <div className="max-sm:hidden relative bg-[#61C5D8] w-full">
        <div className="p-20 text-white relative z-10">
          <div className="font-bold font-cocon text-xlarge mb-10">
            {t("Together We Make an Impact")}
          </div>
          <div className="flex flex-col justify-start text-meduim font-[400] font-Montserrat ">
            <div>{t("Reach out to our advertising team today:")}</div>
            <div className="my-4 ">
              {t("Email")} : <a className="text-white" href="mailto:info@businesshub.com.qa"> info@businesshub.com.qa</a>
            </div>
            <div className="mb-4">
              {t("Phone")} : <a className="text-white" href="tel:+974 66688 584"> +974 66688 584 </a>
            </div>
            <div>{t("Hydrate, Promote, and Inspire with  Naia Water")}</div>
          </div>
        </div>
        <div
          className={`${
            direction === "ltr" ? "right-0" : "left-0 rotate"
          } absolute top-20 max-w-[60%] z-0 `}
        >
          <img loading="lazy" src={hands} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Naia;
