import React from 'react'
import img from 'assets/images/imagesNitcomax/nicomaxAboutImg.webp'
import shape1 from 'assets/icons/iconsNitcomax/nitcomaxShape1About.svg'
import shape2 from 'assets/icons/iconsNitcomax/nitcomaxShape2About.svg'
import { useTranslation } from 'react-i18next';
import { settingsStore } from 'store/settingStore';
import arrow from 'assets/icons/arrowLight.svg'
import { Link } from 'react-router-dom';
const About = () => {
    const { t } = useTranslation('index')
    const direction = settingsStore((state) => state.direction);
    return (
        <div>
            <div className='flex flex-col p-4 md:p-20'>
                <div className='flex flex-col p-12 mb-2'>
                    <div className='text-large font-[600] font-jakarta capitalize'>{t("About us")}</div>
                    <div className='text-meduim font-[600] font-poppins'>{t("nitcomax about paragraph 1")}</div>
                </div>
                <div className='flex gap-12 relative max-lg:flex-col '>
                    <div className='lg:w-2/3 relative flex items-start'>
                        <div className='relative p-6 sm:p-20 flex'>
                            <img loading='lazy' data-aos="zoom-in" data-aos-duration='1000' src={img} alt="" />
                            <div className='absolute z-[-1] max-sm:w-1/5 top-0 right-0'>
                                <img className='' loading='lazy' src={shape1} alt="" />
                            </div>
                            <div className='absolute max-sm:w-1/5 bottom-0 left-0'>
                                <img loading='lazy' src={shape2} alt="" />
                            </div>
                            <div className='absolute bottom-[-50px] sm:bottom-[-20px] md:bottom-[-50px] right-2 flex justify-center'>
                                <div className='py-2 px-8 w-[220px] h-[120px] md:w-[350px] md:h-[150px] text-primary-alhussni-color flex justify-between items-center sm:items-center bg-[#333333]'>
                                    <div className='flex flex-col text-[12px] md:text-[14px] me-2 font-jakarta'>
                                        <span className='text-large font-[600] font-poppins'>
                                            300+
                                        </span>
                                        {t("Working Peoples")}
                                    </div>
                                    <div className='flex flex-col text-[12px] font-jakarta'>
                                        <span className='text-large font-[600] font-poppins'>
                                            20+
                                        </span>
                                        {t("World Office")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-1/3 flex flex-col lg:mt-1 justify-center mt-16'>
                        <div className=''><p className=' text-main font-[500] font-jakarta leading-[33px]'>{t("nitcomax about paragraph 2")}</p></div>
                        <a href="#head" className='mt-2 w-14 h-14 rounded-full bg-[#333333] flex justify-center items-center p-0 m-0'><img className='w-4 rotate-90' src={arrow} alt='' /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
