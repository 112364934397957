import React from "react";
import Hero from "./components/Hero";
import About from "./components/About";
import FuniroGallary from "./components/FuniroGallary";
import FurnitureSlider from "./components/FurnitureSlider";
import { Helmet } from "react-helmet";

const Furniture = () => {
  return (
    <div className="font-Playfair overflow-hidden">
      <Helmet>
        <title>BusinessHub</title>
        <meta property="og:site_name" content="Qatar Online Furniture" />
        <meta
          property="og:url"
          content="https://business-hub.r-link.io/furniture"
        />
        <meta
          name="description"
          content="Elevate your living spaces with Qatar Online Furniture. Explore exquisite designs, and curated collections to transform your home into a haven of style and comfort."
        />

        <meta property="og:title" content="Qatar Online Furniture" />
        <meta
          property="og:type"
          content="Qatar Online Furniture for Display home furniture"
        />
        <meta name="twitter:site" content="@Qatar-Online-Furniture" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Qatar Online Furniture" />
        <meta
          name="twitter:description"
          content="Elevate your living spaces with Qatar Online Furniture. Explore exquisite designs, and curated collections to transform your home into a haven of style and comfort."
        />
      </Helmet>
      {/* here hero section */}
      <div>
        <Hero />
      </div>
      {/* here about section */}
      <div
        className="relative  before:absolute before:inset-0 before:top-[35%] before:z-[-10] before:bg-[#FFF3E9]"
        id="about"
      >
        <About />
      </div>
      {/* here FuniroGallary section */}
      <div className="bg-[#FFF3E9]">
        <FuniroGallary />
      </div>
      {/* here FurnitureSlider section */}
      <div>
        <FurnitureSlider />
      </div>
    </div>
  );
};

export default Furniture;
