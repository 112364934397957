import React from 'react'
import img1 from 'assets/images/imagesFurniture/fernetureImg1.webp'
import img2 from 'assets/images/imagesFurniture/fernetureImg2.webp'
import img3 from 'assets/images/imagesFurniture/fernetureImg3.webp'
import img4 from 'assets/images/imagesFurniture/fernetureImg4.webp'
import img5 from 'assets/images/imagesFurniture/fernetureImg5.webp'
import img6 from 'assets/images/imagesFurniture/fernetureImg6.webp'
import logo from 'assets/icons/qatarLogo.svg'
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation('index')

    return (
        <div className='flex flex-col p-8 md:p-28 font-[400]'>
            <div className=' text-[30px] font-[500] sm:text-xlarge mb-8 tracking-wider'>{t("About us")}</div>
            <div className='text-meduim  font-[400] md:me-10'>{t("qatat about content")}</div>
            <div className='grid grid-cols-1 sm:grid-cols-3 justify-center items-center gap-2 mt-20'>
                <div><img loading='lazy' data-aos='fade-down' data-aos-delay='300' data-aos-duration='1000' src={img1} alt="" /></div>
                <div><img loading='lazy' data-aos='fade-down' data-aos-delay='600' data-aos-duration='1000' src={img2} alt="" /></div>
                <div><img loading='lazy' data-aos='fade-down' data-aos-delay='900' data-aos-duration='1000' src={img3} alt="" /></div>
            </div>
            <div className='flex max-sm:flex-col gap-4'>
                <div className='sm:w-2/3'>
                    <div className='grid grid-cols-2 items-end gap-4 my-8'>
                        <div className='flex flex-col '>
                            <img className='p-6' loading='lazy' src={logo} alt="" />
                            <img loading='lazy' src={img4} alt="" />
                        </div>
                        <div className=''>
                            <img loading='lazy' src={img5} alt="" />
                        </div>
                    </div>
                    <div><p className='max-sm:text-justify text-main xl:text-meduim  font-[400]'>{t("qatat paragraph 1")}</p></div>
                </div>
                <div className='sm:w-1/3 flex flex-col-reverse max-sm:my-4 sm:flex-col justify-center'>
                    <p className='max-sm:text-justify  text-main xl:text-meduim  font-[400]'>{t("qatat paragraph 2")}</p>
                    <div className='max-sm:mb-8'><img loading='lazy' src={img6} alt="" /></div>
                </div>
            </div>
        </div>

    )
}

export default About
