import howWorkImg1 from 'assets/images/imagesNaia/howWorkImg1.png'
import howWorkImg2 from 'assets/images/imagesNaia/howWorkImg2.png'
import howWorkImg3 from 'assets/icons/iconsNaia/HowWorkImg3.svg'
import place1 from 'assets/icons/iconsNaia/place1.svg'
import place2 from 'assets/icons/iconsNaia/place2.svg'
import place3 from 'assets/icons/iconsNaia/place3.svg'
import place4 from 'assets/icons/iconsNaia/place4.svg'
import place5 from 'assets/icons/iconsNaia/place5.svg'
import place6 from 'assets/icons/iconsNaia/place6.svg'
import place7 from 'assets/icons/iconsNaia/place7.svg'
import bottling1 from 'assets/images/imagesNaia/bottling1.webp'
import bottling2 from 'assets/images/imagesNaia/bottling2.webp'
import bottling3 from 'assets/images/imagesNaia/bottling3.webp'
import bottling4 from 'assets/images/imagesNaia/bottling4.webp'
import bottling5 from 'assets/images/imagesNaia/bottling5.webp'
import bottling6 from 'assets/images/imagesNaia/bottling6.webp'
import bottling7 from 'assets/images/imagesNaia/bottling7.webp'
import bottling8 from 'assets/images/imagesNaia/bottling8.webp'
import SliderImg1 from 'assets/images/imagesNaia/sliderNaiaImg1.webp'
import SliderImg2 from 'assets/images/imagesNaia/sliderNaiaImg2.webp'
import SliderImg3 from 'assets/images/imagesNaia/sliderNaiaImg3.webp'
import SliderImg4 from 'assets/images/imagesNaia/sliderNaiaImg4.webp'


export const useNaiaLogic = () => {
    const bottling = [
        { src: bottling1, title: "Parks" },
        { src: bottling2, title: "PUBLIC TRANSPORT" },
        { src: bottling3, title: "UNIVERSITIES" },
        { src: bottling4, title: "MOSQUES" },
        { src: bottling5, title: "PUBLIC AREAS" },
        { src: bottling6, title: "GYMS" },
        { src: bottling7, title: "PUBLIC BUILDINGS" },
        { src: bottling8, title: "MALLS & VENDING MACHINES" },

    ]

    const HowWorks = [
        {
            src: howWorkImg1,
            title: 'Choose Your Message'
        },
        {
            src: howWorkImg2,
            title: 'Water Bottle Mockup'
        },
        {
            src: howWorkImg3,
            title: 'Maximum Exposure'
        },
    ]
    const DataSlider = [
        {
            title: 'Hydration with Purpose',
            content: 'naia slider content 1',
            src: SliderImg1
        },
        {
            title: 'Showcase Your Values',
            content: 'naia slider content 2',
            src: SliderImg2
        },
        {
            title: 'Unmatched Reach',
            content: 'naia slider content 3',
            src: SliderImg3
        },
        {
            title: 'Creatve Possibilities',
            content: 'naia slider content 4',
            src: SliderImg4
        },
    ]
    const distributionPlaces = [
        {
            src: place1,
            title: 'Mosques'
        },
        {
            src: place2,
            title: 'Parks'
        },
        {
            src: place3,
            title: 'Hospitals'
        },
        {
            src: place4,
            title: 'Public Areas around the centre of Doha'
        },
        {
            src: place5,
            title: 'Events & Special Occasions'
        },
        {
            src: place6,
            title: 'Charity Organizations'
        },
        {
            src: place7,
            title: 'International Humanitarian Cause'
        },
    ]
    const compareValues = {
        plastic: {
            qr: [
                {
                    qr: '15,000'
                },
                {
                    qr: '100,000'
                },
                {
                    qr: '32,500'
                },
                {
                    qr: '55,000'
                },
            ],

            bottles: [
                {
                    bottles: '10,000',
                },
                {
                    bottles: '100,000',
                },
                {
                    bottles: '25,000',
                },
                {
                    bottles: '50,000',
                },
            ]
        },
        can: {
            qr: [
                {
                    qr: '30,000'
                },
                {
                    qr: '65,000'
                },
                {
                    qr: '110,000'
                },
                {
                    qr: '200,000'
                },
            ],

            bottles: [
                {
                    bottles: '10,000',
                },
                {
                    bottles: '25,000',
                },
                {
                    bottles: '50,000',
                },
                {
                    bottles: '100,000',
                },
            ]
        },
        carton: {
            qr: [
                {
                    qr: '50,000'
                },
                {
                    qr: '110,000'
                },
                {
                    qr: '200,000'
                },
                {
                    qr: '300,000'
                },
            ],

            bottles: [
                {
                    bottles: '10,000',
                },
                {
                    bottles: '25,000',
                },
                {
                    bottles: '50,000',
                },
                {
                    bottles: '100,000',
                },
            ]
        },
    };

    return {
        HowWorks, DataSlider, distributionPlaces, bottling, compareValues
    }
}