import React from "react";
import Hero from "./components/Hero";
import ServicesAlhussni from "./components/ServicesAlhussni";
import Perfumeimg from "assets/images/imagesAlhussni/alhussnibgImg4.webp";
import bgLastSection from "assets/images/imagesAlhussni/alhussniBgImg5.webp";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import bgLastSectionLeft from "assets/images/imagesAlhussni/bgLastSectionLeft.png";
import bgLastSectionRight from "assets/images/imagesAlhussni/bgLastSectionRight.png";

const Alhussni = () => {
  const { t } = useTranslation("index");

  return (
    <div style={{ overflow: "hidden" }}>
      <Helmet>
        <title>Alhussni</title>
        <meta property="og:site_name" content="Alhussni" />
        <meta
          property="og:url"
          content="https://business-hub.r-link.io/alhussni"
        />
        <meta name="description" content="Discover the essence of luxury with Al Hussein Perfumes. Immerse yourself in a world of captivating fragrances crafted for the discerning. Elevate your senses."/>
        <meta property="og:title" content="Alhussni" />
        <meta property="og:type" content="Alhussni for show perfumes" />

        <meta name="twitter:site" content="@Alhussni" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Alhussni" />
        <meta name="twitter:description" content="Discover the essence of luxury with Al Hussein Perfumes. Immerse yourself in a world of captivating fragrances crafted for the discerning. Elevate your senses." />
      </Helmet>
      {/* here hero section */}
      <div>
        <Hero />
      </div>
      {/* here services section */}
      <div>
        <ServicesAlhussni />
      </div>
      {/* here another section */}
      <div
        className="flex max-md:flex-col-reverse max-lg:items-center"
        id="about"
      >
        <div className="md:w-1/2 text-white flex flex-col justify-center text-meduim p-6 xl:p-16 font-[500] bg-[#00000099]">
          <h3 className="mb-8">
            <span className="text-primary-alhussni-color">
              {t("FOOD & BEVERAGES")}
            </span>{" "}
            {t("NATURAL FLAVOURS")}
          </h3>
          <p>
            <span className="text-black">{t("ALHUSSNI")} </span>
            {t("text4")}
          </p>
        </div>
        <div className="p-8 md:w-1/2">
          <img loading="lazy" src={Perfumeimg} alt="" />
        </div>
      </div>
      {/* here last section in this page */}
      <div>
        <div className="relative max-md:hidden">
          <img className="max-h-[100vh] object-cover" loading="lazy" src={bgLastSection} alt="" />
          <div className="max-md:hidden w-1/2 absolute left-0 top-0 bottom-0 z-10 text-start text-black flex justify-center items-center">
            <div className="pt-20 lg:pb-10 px-20 h-[85%]  lg:h-[70%] flex flex-col justify-between">
              <h1 className="text-large mx-auto font-bold mb-4">GIVAUDAN</h1>
              <p className="mx-auto w-[100%] lg:w-[75%] text-main font-[500]">
                {t("what is the GIVAUDAN")}
              </p>
            </div>
          </div>
          <div className="max-md:hidden w-1/2 absolute right-0 top-0 bottom-0 z-10 text-start text-white flex justify-center items-center">
            <div className="pt-20 lg:pb-12 px-20 h-[85%]  lg:h-[70%] flex flex-col justify-between">
              <h1 className="text-large mx-auto font-[500] mb-4 font-Montserrat">
                TECHNICOFLO
              </h1>
              <p className="mx-auto w-[100%] lg:w-[75%] text-main font-[500]">
                {t("what is the TECHNICOFLO")}
              </p>
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <div className="flex">
            <div className="w-1/2">
              <img src={bgLastSectionLeft} alt="" />
            </div>
            <div className="flex justify-center items-center w-1/2 px-4">
              <p className="mx-auto text-main font-[500]">
                {t("what is the TECHNICOFLO")}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex justify-center items-center w-1/2 px-4">
              <p className="mx-auto text-main font-[500]">
                {t("what is the GIVAUDAN")}
              </p>
            </div>
            <div className="w-1/2">
              <img src={bgLastSectionRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alhussni;
