import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useFurnitureLogic } from "../hooks/useFurnitureLogic";
import { settingsStore } from "store/settingStore";
// import { settingsStore } from "store/settingStore";

const FurnitureSlider = () => {
  const { t } = useTranslation("index");
  const { ImagesSlider } = useFurnitureLogic();
  const direction = settingsStore((state) => state.direction);
  return (
    <div className="furniture max-lg:flex-col font-Playfair p-8 sm:p-20 flex justify-between h-full bg-black gap-4">
      <div className="lg:w-2/5 max-lg:mb-16 font-[400] flex flex-col justify-evenly text-white">
        <div className=" text-[30px] sm:text-large">
          {t("50+ Beautiful rooms inspiration")}
        </div>
        <div className="text-[20px] my-4">{t("qatar gallary paragraph 1")}</div>
        <div className="text-main tracking-widest leading-[38.4px]">
          {t("qatar gallary paragraph 2")}
        </div>
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        navigation={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Navigation, Pagination]}
        className={`w-3/5 sliderGalaryFurniture `}
      >
        <div className="">
          {/* i am taking data from useFurnitureLogic by name ImagesSlider */}
          {ImagesSlider?.map((item, index) => (
            <SwiperSlide className="" key={index}>
              <img loading="lazy" src={item.src} alt="" />
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default FurnitureSlider;
