import arrowdark from "assets/icons/arrow.svg";
import arrowlight from "assets/icons/arrowLight.svg";
import homeHeroImg from "assets/images/imagesHome/businessHupMainImg.webp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SocialMediaIcons } from "static";
import { settingsStore } from "store/settingStore";
import "style/input.css";
const Hero = () => {
  const { t } = useTranslation("index");
  const direction = settingsStore((state) => state.direction);
  const [index, setIndex] = useState(0);
  const handelmessagenext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % messages.length);
  };
  const handleClickPrevious = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? messages.length - 1 : prevIndex - 1
    );
  };
  const messages = ["home Hero slider", "home Hero slider2"];
  return (
    <div className="mt-1">
      <div className="relative w-full h-full">
        <div className="w-full h-full">
          <img
            className={`${
              direction === "ltr" ? "" : "rotate"
            } max-h-[90vh] relative z-0 object-cover`}
            loading="lazy"
            src={homeHeroImg}
            alt=""
          />
        </div>
        <div
          className={` ${
            direction === "ltr" ? "left-0" : " right-0"
          } font-poppins absolute shadow top-0 bottom-0 flex justify-center items-center bg-primary-color h-full max-md:w-auto w-[36%] 2xl:w-[30%] overflow-hidden`}
          data-aos={`fade-${direction === "ltr" ? "right" : "left"}`}
          data-aos-duration="500"
        >
          <div
            className={`${
              direction === "ltr" ? "right-20" : "left-20"
            } w-[1px] h-20 xl:h-35 bg-[#fff] absolute top-0  max-md:hidden`}
          ></div>
          <div className="flex justify-center items-center p-1 overflow-hidden">
            <div className="relative ms-2 max-md:me-4 max-sm:me-4 h-full flex flex-col justify-center items-center w-[35px] overflow-hidden">
              <div className="w-[1px] h-16 md:h-40 bg-[#fff] mb-4 sm:mb-8"></div>
              {/* i am taking data here from file static.js */}
              {SocialMediaIcons({ fill: "white", color: "#21B1E5" }).map(
                (item, index) => (
                  <div className="my-2" key={index}>
                    <a href="https://www.instagram.com/businesshubqa?igsh=MXhoZ3JnMXA3YzVjeg%3D%3D&utm_source=qr">
                      {item.src}
                    </a>
                  </div>
                )
              )}
              <div className="w-[1px] h-16 md:h-40 bg-[#fff] mt-4 sm:mt-8"></div>
            </div>
            <div className=" lg:leading-[50px] text-meduim text-[#fff]  h-full flex flex-col justify-center items-start px-6 w-[80%] max-md:hidden">
              {t("Welcome to")}
              <h1 className="text-xlarge font-[400] my-2">BusinessHub</h1>
              {t("home Hero")}
            </div>
          </div>
          <div className="w-[1px] h-20 xl:h-35 bg-[#fff] absolute bottom-0 max-md:hidden"></div>
        </div>
        {/* mini slider */}
        <div
          className={`${
            direction === "ltr" ? "right-12" : "left-12"
          } font-inter absolute  bottom-10 max-md:hidden`}
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <div className="flex flex-col">
            <div
              className={`${
                direction === "ltr"
                  ? "flex-row justify-end"
                  : "flex-row-reverse justify-start"
              } flex mb-3`}
            >
              <div
                className="cursor-pointer rounded-sm bg-black px-3 py-1 transform "
                onClick={handleClickPrevious}
              >
                <img
                  className="w-4 h-4 xl:w-auto xl:h-auto"
                  src={arrowlight}
                  alt=""
                />
              </div>
              <div
                className="cursor-pointer rounded-sm bg-primary-color px-3 py-1 "
                onClick={handelmessagenext}
              >
                <img
                  className="w-4 h-4 xl:w-auto xl:h-auto"
                  src={arrowdark}
                  alt=""
                />
              </div>
            </div>
            <div
              className="h-36 xl:h-40 w-[300px] xl:w-[350px] bg-[#fff] p-4 xl:p-8 fadein rounded-lg"
              key={index}
            >
              <span className="font-bold">
                {t("Get an instant Quotation to your email")}
              </span>
              {t(messages[index])}
            </div>
          </div>
        </div>
      </div>
      {/* section for mobile only because the same section above will be hidden in mobile */}
      <div className="font-poppins  my-10 lg:leading-[76px] text-meduim text-black flex flex-col justify-center items-start px-4 w-full md:hidden">
        {t("Welcome to")}
        <h1 className="text-[40px] md:text-[64px] font-[400]">
          {" "}
          {t("BusinesHub")}
        </h1>
        {t("home Hero")}
      </div>
    </div>
  );
};

export default Hero;
