import React from 'react';
import qutation from '../../../assets/icons/qutation.svg';
import { useTranslation } from 'react-i18next';

const List = (props) => {
    const { t } = useTranslation('index');
    const li = props.prop1;
    const headerList=props.headerList;
    return (
        <div className={`${props.bgcolor} text-${props.textColor} rounded-lg p-8 flex flex-col h-full`}>
            <div className='flex min-h-[20%] items-center'>
                <img className='w-6 me-4' src={qutation} alt='' />
                <div className='text-meduim font-bold'>{headerList}</div>
            </div>
            <ul className='font-inter ps-12 pt-2 list-disc marker:text-sky-400 flex flex-col h-full justify-center'>
                {li.map((item, index) => (
                    <li className='text-main flex-1' key={index}>{t(item.content)}</li>
                ))}
            </ul>
        </div>
    );
};

export default List;
